@import url(https://fonts.googleapis.com/css?family=Roboto);
@charset "UTF-8";
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.ori-app-container {
  /*---------------------  BASE RULE FOR PROJECT  ---------------------*/
  font-family: "Roboto", sans-serif !important;
  max-width: 100vw;
  color: #3c4043 !important;
  font-size: 14px !important;
  line-height: 1.5;
  direction: ltr !important;
  /*---------------------  BASIC COMMON CLASSES -------------------- */
  /*---------------------- FONT CLASSES -----------------------*/
  /*---------------------- COMMON FLEX CLASSES --------------------- */
  /*---------------------- COMMON PADDING CLASSES----------------------*/
  /*---------------------------- COMMON MARGIN CLASSES-----------------------------*/ }
  .ori-app-container p {
    margin-bottom: 0;
    margin-top: 0; }
  .ori-app-container p, .ori-app-container span, .ori-app-container div, .ori-app-container span, .ori-app-container applet, .ori-app-container object, .ori-app-container iframe, .ori-app-container p, .ori-app-container blockquote, .ori-app-container pre, .ori-app-container a, .ori-app-container abbr, .ori-app-container acronym, .ori-app-container address, .ori-app-container big, .ori-app-container cite, .ori-app-container code, .ori-app-container del, .ori-app-container dfn, .ori-app-container em, .ori-app-container img, .ori-app-container ins, .ori-app-container kbd, .ori-app-container q, .ori-app-container s, .ori-app-container samp, .ori-app-container small, .ori-app-container strike, .ori-app-container strong, .ori-app-container sub, .ori-app-container sup, .ori-app-container tt, .ori-app-container var, .ori-app-container b, .ori-app-container u, .ori-app-container i, .ori-app-container center, .ori-app-container dl, .ori-app-container dt, .ori-app-container dd, .ori-app-container ol, .ori-app-container ul, .ori-app-container li, .ori-app-container fieldset, .ori-app-container form, .ori-app-container label, .ori-app-container legend, .ori-app-container table, .ori-app-container caption, .ori-app-container tbody, .ori-app-container tfoot, .ori-app-container thead, .ori-app-container tr, .ori-app-container th, .ori-app-container td, .ori-app-container article, .ori-app-container aside, .ori-app-container canvas, .ori-app-container details, .ori-app-container embed, .ori-app-container figure, .ori-app-container figcaption, .ori-app-container footer, .ori-app-container header, .ori-app-container hgroup, .ori-app-container menu, .ori-app-container nav, .ori-app-container output, .ori-app-container ruby, .ori-app-container section, .ori-app-container summary, .ori-app-container time, .ori-app-container mark, .ori-app-container audio, .ori-app-container video {
    font-family: "Roboto", sans-serif !important; }
  .ori-app-container .ori-transition-ease {
    transition: .5s ease; }
  .ori-app-container .ori-rotate-180 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .ori-app-container .ori-z-index-1 {
    z-index: 1; }
  .ori-app-container .ori-z-index-99990 {
    z-index: 99990; }
  .ori-app-container .ori-z-index-99992 {
    z-index: 99992; }
  .ori-app-container .ori-z-index-99993 {
    z-index: 99993; }
  .ori-app-container .ori-z-index-99994 {
    z-index: 99994; }
  .ori-app-container .ori-z-index-99995 {
    z-index: 99995; }
  .ori-app-container .ori-z-index-99996 {
    z-index: 99996; }
  .ori-app-container .ori-text-overflow-dotted {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .ori-app-container .ori-block-text-overflow-dotted {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ori-app-container .ori-dotted-after-xs-3 {
    font-size: 12px;
    line-height: 1.3;
    -webkit-line-clamp: 4;
    height: 62.4px; }
  .ori-app-container .ori-placeholder-primary ::-webkit-input-placeholder {
    color: #ed3837 !important; }
  .ori-app-container .ori-placeholder-primary :-ms-input-placeholder {
    color: #ed3837 !important; }
  .ori-app-container .ori-placeholder-primary ::placeholder {
    color: #ed3837 !important; }
  .ori-app-container .ori-overflow-y-auto {
    overflow-y: auto; }
  .ori-app-container .ori-overflow-x-hidden {
    overflow-x: hidden; }
  .ori-app-container .ori-line-height-1 {
    line-height: 1 !important; }
  .ori-app-container .ori-bg-gradient {
    background: linear-gradient(90deg, #be2d2c 0%, #f48887 100%); }
  .ori-app-container .ori-bg-white {
    background-color: #ffffff !important; }
  .ori-app-container .ori-bg-default {
    background-color: #f7f7f9; }
  .ori-app-container .ori-bg-green {
    background-color: #35cd4a; }
  .ori-app-container .ori-bg-warning {
    background-color: #f5a623; }
  .ori-app-container .ori-bg-yellow {
    background-color: #ffcb00; }
  .ori-app-container .ori-bg-danger {
    background-color: #ff6d4a; }
  .ori-app-container .ori-bg-card {
    background-color: rgba(239, 239, 244, 0.3); }
  .ori-app-container .ori-bg-header {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-footer {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-notification-popup {
    background-color: #88898c; }
  .ori-app-container .ori-bg-primary {
    background-color: #ed3837; }
  .ori-app-container .ori-bg-black-light {
    background-color: rgba(0, 0, 0, 0.3); }
  .ori-app-container .ori-bg-popup {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-primary-light {
    background-color: #fdebeb !important; }
  .ori-app-container .ori-bg-size-cover {
    background-size: cover; }
  .ori-app-container .ori-bg-no-repeat {
    background-repeat: no-repeat; }
  .ori-app-container .ori-bg-position-center {
    background-position: center; }
  .ori-app-container .ori-font-light-hover-default {
    cursor: pointer;
    color: #abaeb2; }
    .ori-app-container .ori-font-light-hover-default:hover {
      color: #3c4043; }
  .ori-app-container .ori-popup-light-hover-default {
    cursor: pointer;
    color: #abaeb2; }
    .ori-app-container .ori-popup-light-hover-default:hover {
      color: #344050; }
  .ori-app-container .ori-font-popup {
    color: #344050; }
  .ori-app-container .ori-font-white {
    color: #ffffff; }
  .ori-app-container .ori-font-notification-popup {
    color: #ffffff; }
  .ori-app-container .ori-font-header {
    color: #3c4043; }
  .ori-app-container .ori-font-header-light {
    color: #abaeb2; }
  .ori-app-container .ori-font-primary {
    color: #ed3837 !important; }
  .ori-app-container .ori-font-green {
    color: #35cd4a !important; }
  .ori-app-container .ori-font-warning {
    color: #f5a623 !important; }
  .ori-app-container .ori-font-danger {
    color: #ff6d4a !important; }
  .ori-app-container .ori-font-info {
    color: #4da1ff !important; }
  .ori-app-container .ori-font-yellow {
    color: #ffcb00 !important; }
  .ori-app-container .ori-font-default {
    color: #3c4043 !important; }
  .ori-app-container .ori-font-light {
    color: #abaeb2; }
  .ori-app-container .ori-font-default-hover-light {
    color: #3c4043 !important; }
    .ori-app-container .ori-font-default-hover-light:hover {
      color: #abaeb2 !important; }
  .ori-app-container .ori-font-default-hover-primary {
    color: #3c4043; }
    .ori-app-container .ori-font-default-hover-primary:hover {
      color: #ed3837; }
  .ori-app-container .ori-text-center {
    text-align: center; }
  .ori-app-container .ori-text-left {
    text-align: left; }
  .ori-app-container .ori-cursor-ptr {
    cursor: pointer !important; }
  .ori-app-container .ori-uppercase {
    text-transform: uppercase; }
  .ori-app-container .ori-capitalize {
    text-transform: capitalize; }
  .ori-app-container .ori-capitalize-first::first-letter {
    text-transform: capitalize; }
  .ori-app-container .ori-full-width {
    width: 100% !important; }
  .ori-app-container .ori-max-width-350 {
    max-width: 350px; }
  .ori-app-container .ori-width-10 {
    width: 10px; }
  .ori-app-container .ori-full-height {
    height: 100vh !important; }
  .ori-app-container .ori-full-parent-height {
    height: 100% !important; }
  .ori-app-container .ori-height-10 {
    height: 10px; }
  .ori-app-container .ori-overflow-hidden {
    overflow: hidden; }
  .ori-app-container .ori-display-none {
    display: none !important; }
  .ori-app-container .ori-display-inline-block {
    display: inline-block; }
  .ori-app-container .ori-relative {
    position: relative !important; }
  .ori-app-container .ori-absolute {
    position: absolute !important; }
  .ori-app-container .ori-fixed {
    position: fixed !important; }
  .ori-app-container .ori-align-top-4 {
    top: 4px; }
  .ori-app-container .ori-align-bottom-full {
    bottom: 100%; }
  .ori-app-container .ori-align-bottom {
    bottom: 0; }
  .ori-app-container .ori-align-bottom-full {
    bottom: 100%; }
  .ori-app-container .ori-align-left {
    left: 0; }
  .ori-app-container .ori-align-right {
    right: 0; }
  .ori-app-container .ori-align-full {
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0; }
  .ori-app-container .ori-word-break {
    word-break: break-word !important; }
  .ori-app-container .ori-word-wrap {
    word-wrap: break-word; }
  .ori-app-container .ori-img-contain {
    width: 100%;
    height: 100%; }
  .ori-app-container .ori-box-shadow {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.11); }
  .ori-app-container .ori-box-shadow-dark {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .ori-app-container .ori-border-radius-3 {
    border-radius: 3px; }
  .ori-app-container .ori-border-radius-5 {
    border-radius: 5px; }
  .ori-app-container .ori-border-radius-10 {
    border-radius: 10px; }
  .ori-app-container .ori-border-radius-20 {
    border-radius: 20px; }
  .ori-app-container .ori-border-circle {
    border-radius: 50% !important; }
  .ori-app-container .ori-border-light {
    border: 1px solid #ebebeb; }
  .ori-app-container .ori-t-border-light {
    border-top: 1px solid #ebebeb; }
  .ori-app-container .ori-b-border-light {
    border-bottom: 1px solid #ebebeb; }
  .ori-app-container .ori-border-none {
    border: none !important; }
  .ori-app-container .ori-border-light {
    border: 1px solid #ebebeb; }
  .ori-app-container .ori-border-primary {
    border: 1px solid #ed3837 !important; }
  .ori-app-container .ori-border-dashed-default {
    border: 1px dashed #3c4043; }
  .ori-app-container .ori-animation-half {
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important; }
  .ori-app-container .ori-font-thin {
    font-weight: 300; }
  .ori-app-container .ori-font-normal {
    font-weight: 400; }
  .ori-app-container .ori-font-medium {
    font-weight: 500; }
  .ori-app-container .ori-font-bold {
    font-weight: 700; }
  .ori-app-container .ori-font-ultra-bold {
    font-weight: 900; }
  .ori-app-container .ori-font-xxs {
    font-size: 10px !important; }
  .ori-app-container .ori-font-xs {
    font-size: 12px !important; }
  .ori-app-container .ori-font-sm {
    font-size: 14px !important; }
  .ori-app-container .ori-font-md {
    font-size: 16px !important; }
  .ori-app-container .ori-font-lg {
    font-size: 20px !important; }
  .ori-app-container .ori-flex {
    display: flex; }
  .ori-app-container .ori-flex-wrap {
    flex-wrap: wrap; }
  .ori-app-container .ori-flex-row {
    display: flex !important;
    flex-direction: row; }
  .ori-app-container .ori-flex-column {
    display: flex !important;
    flex-direction: column; }
  .ori-app-container .ori-flex-center {
    justify-content: center;
    align-items: center; }
  .ori-app-container .ori-flex-ac {
    align-items: center; }
  .ori-app-container .ori-flex-jc {
    justify-content: center; }
  .ori-app-container .ori-flex-jsb {
    justify-content: space-between; }
  .ori-app-container .ori-flex-jsa {
    justify-content: space-around; }
  .ori-app-container .ori-flex-jfe {
    justify-content: flex-end; }
  .ori-app-container .ori-full-flex {
    flex: 1 1; }
  .ori-app-container .ori-no-pad {
    padding: 0px !important; }
  .ori-app-container .ori-pad-3 {
    padding: 3px !important; }
  .ori-app-container .ori-pad-5 {
    padding: 5px !important; }
  .ori-app-container .ori-pad-7 {
    padding: 7px; }
  .ori-app-container .ori-pad-10 {
    padding: 10px !important; }
  .ori-app-container .ori-pad-12 {
    padding: 12px !important; }
  .ori-app-container .ori-pad-15 {
    padding: 15px !important; }
  .ori-app-container .ori-pad-20 {
    padding: 20px !important; }
  .ori-app-container .ori-lr-pad-5 {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .ori-app-container .ori-lr-pad-8 {
    padding-left: 8px !important;
    padding-right: 8px !important; }
  .ori-app-container .ori-lr-pad-10 {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .ori-app-container .ori-lr-pad-15 {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .ori-app-container .ori-lr-pad-20 {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .ori-app-container .ori-lr-pad-30 {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .ori-app-container .ori-lr-pad-50 {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .ori-app-container .ori-no-tb-pad {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .ori-app-container .ori-tb-pad-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .ori-app-container .ori-tb-pad-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .ori-app-container .ori-tb-pad-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .ori-app-container .ori-tb-pad-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .ori-app-container .ori-l-pad-5 {
    padding-left: 5px; }
  .ori-app-container .ori-l-pad-10 {
    padding-left: 10px !important; }
  .ori-app-container .ori-l-pad-15 {
    padding-left: 15px !important; }
  .ori-app-container .ori-l-pad-20 {
    padding-left: 20px; }
  .ori-app-container .ori-l-pad-25 {
    padding-left: 25px; }
  .ori-app-container .ori-r-pad-5 {
    padding-right: 5px; }
  .ori-app-container .ori-r-pad-10 {
    padding-right: 10px; }
  .ori-app-container .ori-r-pad-20 {
    padding-right: 20px; }
  .ori-app-container .ori-r-pad-40 {
    padding-right: 40px; }
  .ori-app-container .ori-t-pad-3 {
    padding-top: 3px; }
  .ori-app-container .ori-t-pad-5 {
    padding-top: 5px; }
  .ori-app-container .ori-t-pad-10 {
    padding-top: 10px; }
  .ori-app-container .ori-t-pad-15 {
    padding-top: 15px; }
  .ori-app-container .ori-t-pad-20 {
    padding-top: 20px; }
  .ori-app-container .ori-no-b-pad {
    padding-bottom: 0px !important; }
  .ori-app-container .ori-b-pad-5 {
    padding-bottom: 5px !important; }
  .ori-app-container .ori-b-pad-10 {
    padding-bottom: 10px; }
  .ori-app-container .ori-b-pad-15 {
    padding-bottom: 15px; }
  .ori-app-container .ori-b-pad-25 {
    padding-bottom: 25px; }
  .ori-app-container .ori-b-pad-40 {
    padding-bottom: 40px !important; }
  .ori-app-container .ori-no-b-mrgn {
    margin-bottom: 0px !important; }
  .ori-app-container .ori-no-t-mrgn {
    margin-top: 0px !important; }
  .ori-app-container .ori-lr-mrgn-5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .ori-app-container .ori-lr-mrgn-10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .ori-app-container .ori-tb-mrgn-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .ori-app-container .ori-tb-mrgn-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .ori-app-container .ori-l-mrgn-5 {
    margin-left: 5px !important; }
  .ori-app-container .ori-l-mrgn-10 {
    margin-left: 10px; }
  .ori-app-container .ori-l-mrgn-15 {
    margin-left: 15px; }
  .ori-app-container .ori-r-mrgn-5 {
    margin-right: 5px; }
  .ori-app-container .ori-r-mrgn-10 {
    margin-right: 10px; }
  .ori-app-container .ori-r-mrgn-15 {
    margin-right: 15px; }
  .ori-app-container .ori-t-mrgn-3 {
    margin-top: 3px; }
  .ori-app-container .ori-t-mrgn-5 {
    margin-top: 5px; }
  .ori-app-container .ori-t-mrgn-10 {
    margin-top: 10px; }
  .ori-app-container .ori-t-mrgn-15 {
    margin-top: 15px; }
  .ori-app-container .ori-b-mrgn-5 {
    margin-bottom: 5px; }
  .ori-app-container .ori-b-mrgn-7 {
    margin-bottom: 7px; }
  .ori-app-container .ori-b-mrgn-10 {
    margin-bottom: 10px; }
  .ori-app-container .ori-mrgn-auto {
    margin: auto; }
  .ori-app-container .ori-mrgn-10 {
    margin: 10px; }
  .ori-app-container .ori-mrgn-20 {
    margin: 20px; }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
@-webkit-keyframes ori-pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes ori-pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes ori-ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  35% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0.6; }
  75% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    opacity: 0.4; }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes ori-ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  35% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0.6; }
  75% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    opacity: 0.4; }
  100% {
    opacity: 0.1;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@-webkit-keyframes ori-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes ori-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@-webkit-keyframes ori-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ori-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes ori-fade-in-right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes ori-fade-in-right {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ori-fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes ori-fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ori-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes ori-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes ori-fade-out-right {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes ori-fade-out-right {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes ori-fade-out-down {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes ori-fade-out-down {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes ori-zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes ori-zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes ori-zoom-out {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes ori-zoom-out {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@-webkit-keyframes ori-translate-down {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes ori-translate-down {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ori-zoom-in-bottom-left {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 1000px, -1000px);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 1000px, -1000px);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease; } }

@keyframes ori-zoom-in-bottom-left {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 1000px, -1000px);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 1000px, -1000px);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease; } }

@-webkit-keyframes ori-zoom-out-bottom-left {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate(-1000px, 1300px);
    transform: scale(0.1) translate(-1000px, 1300px);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; } }

@keyframes ori-zoom-out-bottom-left {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate(-1000px, 1300px);
    transform: scale(0.1) translate(-1000px, 1300px);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease; } }

.ori-animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.ori-infinite {
  -webkit-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important; }

.ori-ripple {
  -webkit-animation: ori-ripple 1.5s;
  animation: ori-ripple 1.5s; }

.ori-pulse {
  -webkit-animation: ori-pulse 1s;
  animation: ori-pulse 1s; }

.ori-rotate {
  -webkit-animation: ori-rotate 1s;
  animation: ori-rotate 1s; }

.ori-translate-down {
  -webkit-animation: ori-translate-down 1s;
  animation: ori-translate-down 1s; }

.ori-fade-in {
  -webkit-animation: ori-fade-in 1s;
  animation: ori-fade-in 1s; }

.ori-fade-in-up {
  -webkit-animation: ori-fade-in-up 1s;
  animation: ori-fade-in-up 1s; }

.ori-fade-out {
  -webkit-animation: ori-fade-out 1s;
  animation: ori-fade-out 1s; }

.ori-fade-out-left {
  -webkit-animation: ori-fade-out-left 1s;
  animation: ori-fade-out-left 1s; }

.ori-fade-out-down {
  -webkit-animation: ori-fade-out-down 1s;
  animation: ori-fade-out-down 1s; }

.ori-zoom-in {
  -webkit-animation: ori-zoom-in 1s;
  animation: ori-zoom-in 1s; }

.ori-zoom-out {
  -webkit-animation: ori-zoom-out 1s;
  animation: ori-zoom-out 1s; }

.ori-zoom-in-bottom-left {
  -webkit-animation: ori-zoom-in-bottom-left 1s;
  animation: ori-zoom-in-bottom-left 1s; }

.ori-zoom-out-bottom-left {
  -webkit-animation: ori-zoom-out-bottom-left 1s;
  animation: ori-zoom-out-bottom-left 1s; }

@font-face {
  font-family: "Monospaced Number";
  src: local("Tahoma");
  unicode-range: U+30-39; }

@font-face {
  font-family: "Chinese Quote";
  src: local("PingFang SC"), local("SimSun");
  unicode-range: U+2018, U+2019, U+201c, U+201d; }

@font-face {
  font-family: 'anticon';
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot");
  /* IE9*/
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff") format("woff"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf") format("truetype"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont") format("svg"); }

.ori-ant-design-container input::-ms-clear,
.ori-ant-design-container input::-ms-reveal {
  display: none; }

.ori-ant-design-container *,
.ori-ant-design-container *::before,
.ori-ant-design-container *::after {
  box-sizing: border-box; }

.ori-ant-design-container article,
.ori-ant-design-container aside,
.ori-ant-design-container dialog,
.ori-ant-design-container figcaption,
.ori-ant-design-container figure,
.ori-ant-design-container footer,
.ori-ant-design-container header,
.ori-ant-design-container hgroup,
.ori-ant-design-container main,
.ori-ant-design-container nav,
.ori-ant-design-container section {
  display: block; }

.ori-ant-design-container [tabindex="-1"]:focus {
  outline: none !important; }

.ori-ant-design-container hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.ori-ant-design-container h1,
.ori-ant-design-container h2,
.ori-ant-design-container h3,
.ori-ant-design-container h4,
.ori-ant-design-container h5,
.ori-ant-design-container h6 {
  margin-top: 0;
  margin-bottom: .5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500; }

.ori-ant-design-container abbr[title],
.ori-ant-design-container abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

.ori-ant-design-container address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit; }

.ori-ant-design-container input[type="text"],
.ori-ant-design-container input[type="password"],
.ori-ant-design-container input[type="number"],
.ori-ant-design-container textarea {
  -webkit-appearance: none; }

.ori-ant-design-container ol,
.ori-ant-design-container ul,
.ori-ant-design-container dl {
  margin-top: 0;
  margin-bottom: 1em; }

.ori-ant-design-container ol ol,
.ori-ant-design-container ul ul,
.ori-ant-design-container ol ul,
.ori-ant-design-container ul ol {
  margin-bottom: 0; }

.ori-ant-design-container dt {
  font-weight: 500; }

.ori-ant-design-container dd {
  margin-bottom: .5em;
  margin-left: 0; }

.ori-ant-design-container blockquote {
  margin: 0 0 1em; }

.ori-ant-design-container dfn {
  font-style: italic; }

.ori-ant-design-container b,
.ori-ant-design-container strong {
  font-weight: bolder; }

.ori-ant-design-container small {
  font-size: 80%; }

.ori-ant-design-container sub,
.ori-ant-design-container sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

.ori-ant-design-container sub {
  bottom: -0.25em; }

.ori-ant-design-container sup {
  top: -0.5em; }

.ori-ant-design-container a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects; }

.ori-ant-design-container a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink; }

.ori-ant-design-container a:hover {
  color: #40a9ff; }

.ori-ant-design-container a:active {
  color: #096dd9; }

.ori-ant-design-container a:active,
.ori-ant-design-container a:hover {
  outline: 0;
  text-decoration: none; }

.ori-ant-design-container a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none; }

.ori-ant-design-container pre,
.ori-ant-design-container code,
.ori-ant-design-container kbd,
.ori-ant-design-container samp {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 1em; }

.ori-ant-design-container pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto; }

.ori-ant-design-container figure {
  margin: 0 0 1em; }

.ori-ant-design-container img {
  vertical-align: middle;
  border-style: none; }

.ori-ant-design-container svg:not(:root) {
  overflow: hidden; }

.ori-ant-design-container a,
.ori-ant-design-container area,
.ori-ant-design-container button,
.ori-ant-design-container [role="button"],
.ori-ant-design-container input:not([type=range]),
.ori-ant-design-container label,
.ori-ant-design-container select,
.ori-ant-design-container summary,
.ori-ant-design-container textarea {
  touch-action: manipulation; }

.ori-ant-design-container table {
  border-collapse: collapse; }

.ori-ant-design-container caption {
  padding-top: .75em;
  padding-bottom: .3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom; }

.ori-ant-design-container th {
  text-align: inherit; }

.ori-ant-design-container input,
.ori-ant-design-container button,
.ori-ant-design-container select,
.ori-ant-design-container optgroup,
.ori-ant-design-container textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }

.ori-ant-design-container button,
.ori-ant-design-container input {
  overflow: visible; }

.ori-ant-design-container button,
.ori-ant-design-container select {
  text-transform: none; }

.ori-ant-design-container button,
.ori-ant-design-container [type="button"],
.ori-ant-design-container [type="reset"],
.ori-ant-design-container [type="submit"] {
  -webkit-appearance: button; }

.ori-ant-design-container button::-moz-focus-inner,
.ori-ant-design-container [type="button"]::-moz-focus-inner,
.ori-ant-design-container [type="reset"]::-moz-focus-inner,
.ori-ant-design-container [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

.ori-ant-design-container input[type="radio"],
.ori-ant-design-container input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

.ori-ant-design-container input[type="date"],
.ori-ant-design-container input[type="time"],
.ori-ant-design-container input[type="datetime-local"],
.ori-ant-design-container input[type="month"] {
  -webkit-appearance: listbox; }

.ori-ant-design-container textarea {
  overflow: auto;
  resize: vertical; }

.ori-ant-design-container fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.ori-ant-design-container legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5em;
  font-size: 1.5em;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

.ori-ant-design-container progress {
  vertical-align: baseline; }

.ori-ant-design-container [type="number"]::-webkit-inner-spin-button,
.ori-ant-design-container [type="number"]::-webkit-outer-spin-button {
  height: auto; }

.ori-ant-design-container [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

.ori-ant-design-container [type="search"]::-webkit-search-cancel-button,
.ori-ant-design-container [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

.ori-ant-design-container ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

.ori-ant-design-container output {
  display: inline-block; }

.ori-ant-design-container summary {
  display: list-item; }

.ori-ant-design-container template {
  display: none; }

.ori-ant-design-container [hidden] {
  display: none !important; }

.ori-ant-design-container mark {
  padding: .2em;
  background-color: #feffe6; }

.ori-ant-design-container ::selection {
  background: #1890ff;
  color: #fff; }

.ori-ant-design-container .clearfix {
  zoom: 1; }

.ori-ant-design-container .clearfix:before,
.ori-ant-design-container .clearfix:after {
  content: " ";
  display: table; }

.ori-ant-design-container .clearfix:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0; }

.ori-ant-design-container .anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ori-ant-design-container .anticon:before {
  display: block;
  font-family: "anticon" !important; }

.ori-ant-design-container .anticon-step-forward:before {
  content: "\e600"; }

.ori-ant-design-container .anticon-step-backward:before {
  content: "\e601"; }

.ori-ant-design-container .anticon-forward:before {
  content: "\e602"; }

.ori-ant-design-container .anticon-backward:before {
  content: "\e603"; }

.ori-ant-design-container .anticon-caret-right:before {
  content: "\e604"; }

.ori-ant-design-container .anticon-caret-left:before {
  content: "\e605"; }

.ori-ant-design-container .anticon-caret-down:before {
  content: "\e606"; }

.ori-ant-design-container .anticon-caret-up:before {
  content: "\e607"; }

.ori-ant-design-container .anticon-right-circle:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-circle-right:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-caret-circle-right:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-left-circle:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-circle-left:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-caret-circle-left:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-up-circle:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-circle-up:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-caret-circle-up:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-down-circle:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-circle-down:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-caret-circle-down:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-right-circle-o:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-circle-o-right:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-caret-circle-o-right:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-left-circle-o:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-circle-o-left:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-caret-circle-o-left:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-up-circle-o:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-circle-o-up:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-caret-circle-o-up:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-down-circle-o:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-circle-o-down:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-caret-circle-o-down:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-verticle-left:before {
  content: "\e610"; }

.ori-ant-design-container .anticon-verticle-right:before {
  content: "\e611"; }

.ori-ant-design-container .anticon-rollback:before {
  content: "\e612"; }

.ori-ant-design-container .anticon-retweet:before {
  content: "\e613"; }

.ori-ant-design-container .anticon-shrink:before {
  content: "\e614"; }

.ori-ant-design-container .anticon-arrows-alt:before {
  content: "\e615"; }

.ori-ant-design-container .anticon-arrow-salt:before {
  content: "\e615"; }

.ori-ant-design-container .anticon-reload:before {
  content: "\e616"; }

.ori-ant-design-container .anticon-double-right:before {
  content: "\e617"; }

.ori-ant-design-container .anticon-double-left:before {
  content: "\e618"; }

.ori-ant-design-container .anticon-arrow-down:before {
  content: "\e619"; }

.ori-ant-design-container .anticon-arrow-up:before {
  content: "\e61a"; }

.ori-ant-design-container .anticon-arrow-right:before {
  content: "\e61b"; }

.ori-ant-design-container .anticon-arrow-left:before {
  content: "\e61c"; }

.ori-ant-design-container .anticon-down:before {
  content: "\e61d"; }

.ori-ant-design-container .anticon-up:before {
  content: "\e61e"; }

.ori-ant-design-container .anticon-right:before {
  content: "\e61f"; }

.ori-ant-design-container .anticon-left:before {
  content: "\e620"; }

.ori-ant-design-container .anticon-minus-square-o:before {
  content: "\e621"; }

.ori-ant-design-container .anticon-minus-circle:before {
  content: "\e622"; }

.ori-ant-design-container .anticon-minus-circle-o:before {
  content: "\e623"; }

.ori-ant-design-container .anticon-minus:before {
  content: "\e624"; }

.ori-ant-design-container .anticon-plus-circle-o:before {
  content: "\e625"; }

.ori-ant-design-container .anticon-plus-circle:before {
  content: "\e626"; }

.ori-ant-design-container .anticon-plus:before {
  content: "\e627"; }

.ori-ant-design-container .anticon-info-circle:before {
  content: "\e628"; }

.ori-ant-design-container .anticon-info-circle-o:before {
  content: "\e629"; }

.ori-ant-design-container .anticon-info:before {
  content: "\e62a"; }

.ori-ant-design-container .anticon-exclamation:before {
  content: "\e62b"; }

.ori-ant-design-container .anticon-exclamation-circle:before {
  content: "\e62c"; }

.ori-ant-design-container .anticon-exclamation-circle-o:before {
  content: "\e62d"; }

.ori-ant-design-container .anticon-close-circle:before {
  content: "\e62e"; }

.ori-ant-design-container .anticon-cross-circle:before {
  content: "\e62e"; }

.ori-ant-design-container .anticon-close-circle-o:before {
  content: "\e62f"; }

.ori-ant-design-container .anticon-cross-circle-o:before {
  content: "\e62f"; }

.ori-ant-design-container .anticon-check-circle:before {
  content: "\e630"; }

.ori-ant-design-container .anticon-check-circle-o:before {
  content: "\e631"; }

.ori-ant-design-container .anticon-check:before {
  content: "\e632"; }

.ori-ant-design-container .anticon-close:before {
  content: "\e633"; }

.ori-ant-design-container .anticon-cross:before {
  content: "\e633"; }

.ori-ant-design-container .anticon-customer-service:before {
  content: "\e634"; }

.ori-ant-design-container .anticon-customerservice:before {
  content: "\e634"; }

.ori-ant-design-container .anticon-credit-card:before {
  content: "\e635"; }

.ori-ant-design-container .anticon-code-o:before {
  content: "\e636"; }

.ori-ant-design-container .anticon-book:before {
  content: "\e637"; }

.ori-ant-design-container .anticon-bars:before {
  content: "\e639"; }

.ori-ant-design-container .anticon-question:before {
  content: "\e63a"; }

.ori-ant-design-container .anticon-question-circle:before {
  content: "\e63b"; }

.ori-ant-design-container .anticon-question-circle-o:before {
  content: "\e63c"; }

.ori-ant-design-container .anticon-pause:before {
  content: "\e63d"; }

.ori-ant-design-container .anticon-pause-circle:before {
  content: "\e63e"; }

.ori-ant-design-container .anticon-pause-circle-o:before {
  content: "\e63f"; }

.ori-ant-design-container .anticon-clock-circle:before {
  content: "\e640"; }

.ori-ant-design-container .anticon-clock-circle-o:before {
  content: "\e641"; }

.ori-ant-design-container .anticon-swap:before {
  content: "\e642"; }

.ori-ant-design-container .anticon-swap-left:before {
  content: "\e643"; }

.ori-ant-design-container .anticon-swap-right:before {
  content: "\e644"; }

.ori-ant-design-container .anticon-plus-square-o:before {
  content: "\e645"; }

.ori-ant-design-container .anticon-frown:before {
  content: "\e646"; }

.ori-ant-design-container .anticon-frown-circle:before {
  content: "\e646"; }

.ori-ant-design-container .anticon-ellipsis:before {
  content: "\e647"; }

.ori-ant-design-container .anticon-copy:before {
  content: "\e648"; }

.ori-ant-design-container .anticon-menu-fold:before {
  content: "\e9ac"; }

.ori-ant-design-container .anticon-mail:before {
  content: "\e659"; }

.ori-ant-design-container .anticon-logout:before {
  content: "\e65a"; }

.ori-ant-design-container .anticon-link:before {
  content: "\e65b"; }

.ori-ant-design-container .anticon-area-chart:before {
  content: "\e65c"; }

.ori-ant-design-container .anticon-line-chart:before {
  content: "\e65d"; }

.ori-ant-design-container .anticon-home:before {
  content: "\e65e"; }

.ori-ant-design-container .anticon-laptop:before {
  content: "\e65f"; }

.ori-ant-design-container .anticon-star:before {
  content: "\e660"; }

.ori-ant-design-container .anticon-star-o:before {
  content: "\e661"; }

.ori-ant-design-container .anticon-folder:before {
  content: "\e662"; }

.ori-ant-design-container .anticon-filter:before {
  content: "\e663"; }

.ori-ant-design-container .anticon-file:before {
  content: "\e664"; }

.ori-ant-design-container .anticon-exception:before {
  content: "\e665"; }

.ori-ant-design-container .anticon-meh:before {
  content: "\e666"; }

.ori-ant-design-container .anticon-meh-circle:before {
  content: "\e666"; }

.ori-ant-design-container .anticon-meh-o:before {
  content: "\e667"; }

.ori-ant-design-container .anticon-shopping-cart:before {
  content: "\e668"; }

.ori-ant-design-container .anticon-save:before {
  content: "\e669"; }

.ori-ant-design-container .anticon-user:before {
  content: "\e66a"; }

.ori-ant-design-container .anticon-video-camera:before {
  content: "\e66b"; }

.ori-ant-design-container .anticon-to-top:before {
  content: "\e66c"; }

.ori-ant-design-container .anticon-team:before {
  content: "\e66d"; }

.ori-ant-design-container .anticon-tablet:before {
  content: "\e66e"; }

.ori-ant-design-container .anticon-solution:before {
  content: "\e66f"; }

.ori-ant-design-container .anticon-search:before {
  content: "\e670"; }

.ori-ant-design-container .anticon-share-alt:before {
  content: "\e671"; }

.ori-ant-design-container .anticon-setting:before {
  content: "\e672"; }

.ori-ant-design-container .anticon-poweroff:before {
  content: "\e6d5"; }

.ori-ant-design-container .anticon-picture:before {
  content: "\e674"; }

.ori-ant-design-container .anticon-phone:before {
  content: "\e675"; }

.ori-ant-design-container .anticon-paper-clip:before {
  content: "\e676"; }

.ori-ant-design-container .anticon-notification:before {
  content: "\e677"; }

.ori-ant-design-container .anticon-mobile:before {
  content: "\e678"; }

.ori-ant-design-container .anticon-menu-unfold:before {
  content: "\e9ad"; }

.ori-ant-design-container .anticon-inbox:before {
  content: "\e67a"; }

.ori-ant-design-container .anticon-lock:before {
  content: "\e67b"; }

.ori-ant-design-container .anticon-qrcode:before {
  content: "\e67c"; }

.ori-ant-design-container .anticon-play-circle:before {
  content: "\e6d0"; }

.ori-ant-design-container .anticon-play-circle-o:before {
  content: "\e6d1"; }

.ori-ant-design-container .anticon-tag:before {
  content: "\e6d2"; }

.ori-ant-design-container .anticon-tag-o:before {
  content: "\e6d3"; }

.ori-ant-design-container .anticon-tags:before {
  content: "\e67d"; }

.ori-ant-design-container .anticon-tags-o:before {
  content: "\e67e"; }

.ori-ant-design-container .anticon-cloud-o:before {
  content: "\e67f"; }

.ori-ant-design-container .anticon-cloud:before {
  content: "\e680"; }

.ori-ant-design-container .anticon-cloud-upload:before {
  content: "\e681"; }

.ori-ant-design-container .anticon-cloud-download:before {
  content: "\e682"; }

.ori-ant-design-container .anticon-cloud-download-o:before {
  content: "\e683"; }

.ori-ant-design-container .anticon-cloud-upload-o:before {
  content: "\e684"; }

.ori-ant-design-container .anticon-environment:before {
  content: "\e685"; }

.ori-ant-design-container .anticon-environment-o:before {
  content: "\e686"; }

.ori-ant-design-container .anticon-eye:before {
  content: "\e687"; }

.ori-ant-design-container .anticon-eye-o:before {
  content: "\e688"; }

.ori-ant-design-container .anticon-camera:before {
  content: "\e689"; }

.ori-ant-design-container .anticon-camera-o:before {
  content: "\e68a"; }

.ori-ant-design-container .anticon-windows:before {
  content: "\e68b"; }

.ori-ant-design-container .anticon-apple:before {
  content: "\e68c"; }

.ori-ant-design-container .anticon-apple-o:before {
  content: "\e6d4"; }

.ori-ant-design-container .anticon-android:before {
  content: "\e938"; }

.ori-ant-design-container .anticon-android-o:before {
  content: "\e68d"; }

.ori-ant-design-container .anticon-aliwangwang:before {
  content: "\e68e"; }

.ori-ant-design-container .anticon-aliwangwang-o:before {
  content: "\e68f"; }

.ori-ant-design-container .anticon-export:before {
  content: "\e691"; }

.ori-ant-design-container .anticon-edit:before {
  content: "\e692"; }

.ori-ant-design-container .anticon-circle-down-o:before {
  content: "\e693"; }

.ori-ant-design-container .anticon-circle-down-:before {
  content: "\e694"; }

.ori-ant-design-container .anticon-appstore-o:before {
  content: "\e695"; }

.ori-ant-design-container .anticon-appstore:before {
  content: "\e696"; }

.ori-ant-design-container .anticon-scan:before {
  content: "\e697"; }

.ori-ant-design-container .anticon-file-text:before {
  content: "\e698"; }

.ori-ant-design-container .anticon-folder-open:before {
  content: "\e699"; }

.ori-ant-design-container .anticon-hdd:before {
  content: "\e69a"; }

.ori-ant-design-container .anticon-ie:before {
  content: "\e69b"; }

.ori-ant-design-container .anticon-file-jpg:before {
  content: "\e69c"; }

.ori-ant-design-container .anticon-like:before {
  content: "\e64c"; }

.ori-ant-design-container .anticon-like-o:before {
  content: "\e69d"; }

.ori-ant-design-container .anticon-dislike:before {
  content: "\e64b"; }

.ori-ant-design-container .anticon-dislike-o:before {
  content: "\e69e"; }

.ori-ant-design-container .anticon-delete:before {
  content: "\e69f"; }

.ori-ant-design-container .anticon-enter:before {
  content: "\e6a0"; }

.ori-ant-design-container .anticon-pushpin-o:before {
  content: "\e6a1"; }

.ori-ant-design-container .anticon-pushpin:before {
  content: "\e6a2"; }

.ori-ant-design-container .anticon-heart:before {
  content: "\e6a3"; }

.ori-ant-design-container .anticon-heart-o:before {
  content: "\e6a4"; }

.ori-ant-design-container .anticon-pay-circle:before {
  content: "\e6a5"; }

.ori-ant-design-container .anticon-pay-circle-o:before {
  content: "\e6a6"; }

.ori-ant-design-container .anticon-smile:before {
  content: "\e6a7"; }

.ori-ant-design-container .anticon-smile-circle:before {
  content: "\e6a7"; }

.ori-ant-design-container .anticon-smile-o:before {
  content: "\e6a8"; }

.ori-ant-design-container .anticon-frown-o:before {
  content: "\e6a9"; }

.ori-ant-design-container .anticon-calculator:before {
  content: "\e6aa"; }

.ori-ant-design-container .anticon-message:before {
  content: "\e6ab"; }

.ori-ant-design-container .anticon-chrome:before {
  content: "\e6ac"; }

.ori-ant-design-container .anticon-github:before {
  content: "\e6ad"; }

.ori-ant-design-container .anticon-file-unknown:before {
  content: "\e6af"; }

.ori-ant-design-container .anticon-file-excel:before {
  content: "\e6b0"; }

.ori-ant-design-container .anticon-file-ppt:before {
  content: "\e6b1"; }

.ori-ant-design-container .anticon-file-word:before {
  content: "\e6b2"; }

.ori-ant-design-container .anticon-file-pdf:before {
  content: "\e6b3"; }

.ori-ant-design-container .anticon-desktop:before {
  content: "\e6b4"; }

.ori-ant-design-container .anticon-upload:before {
  content: "\e6b6"; }

.ori-ant-design-container .anticon-download:before {
  content: "\e6b7"; }

.ori-ant-design-container .anticon-pie-chart:before {
  content: "\e6b8"; }

.ori-ant-design-container .anticon-unlock:before {
  content: "\e6ba"; }

.ori-ant-design-container .anticon-calendar:before {
  content: "\e6bb"; }

.ori-ant-design-container .anticon-windows-o:before {
  content: "\e6bc"; }

.ori-ant-design-container .anticon-dot-chart:before {
  content: "\e6bd"; }

.ori-ant-design-container .anticon-bar-chart:before {
  content: "\e6be"; }

.ori-ant-design-container .anticon-code:before {
  content: "\e6bf"; }

.ori-ant-design-container .anticon-api:before {
  content: "\e951"; }

.ori-ant-design-container .anticon-plus-square:before {
  content: "\e6c0"; }

.ori-ant-design-container .anticon-minus-square:before {
  content: "\e6c1"; }

.ori-ant-design-container .anticon-close-square:before {
  content: "\e6c2"; }

.ori-ant-design-container .anticon-close-square-o:before {
  content: "\e6c3"; }

.ori-ant-design-container .anticon-check-square:before {
  content: "\e6c4"; }

.ori-ant-design-container .anticon-check-square-o:before {
  content: "\e6c5"; }

.ori-ant-design-container .anticon-fast-backward:before {
  content: "\e6c6"; }

.ori-ant-design-container .anticon-fast-forward:before {
  content: "\e6c7"; }

.ori-ant-design-container .anticon-up-square:before {
  content: "\e6c8"; }

.ori-ant-design-container .anticon-down-square:before {
  content: "\e6c9"; }

.ori-ant-design-container .anticon-left-square:before {
  content: "\e6ca"; }

.ori-ant-design-container .anticon-right-square:before {
  content: "\e6cb"; }

.ori-ant-design-container .anticon-right-square-o:before {
  content: "\e6cc"; }

.ori-ant-design-container .anticon-left-square-o:before {
  content: "\e6cd"; }

.ori-ant-design-container .anticon-down-square-o:before {
  content: "\e6ce"; }

.ori-ant-design-container .anticon-up-square-o:before {
  content: "\e6cf"; }

.ori-ant-design-container .anticon-loading:before {
  content: "\e64d"; }

.ori-ant-design-container .anticon-loading-3-quarters:before {
  content: "\e6ae"; }

.ori-ant-design-container .anticon-bulb:before {
  content: "\e649"; }

.ori-ant-design-container .anticon-select:before {
  content: "\e64a"; }

.ori-ant-design-container .anticon-addfile:before,
.ori-ant-design-container .anticon-file-add:before {
  content: "\e910"; }

.ori-ant-design-container .anticon-addfolder:before,
.ori-ant-design-container .anticon-folder-add:before {
  content: "\e914"; }

.ori-ant-design-container .anticon-switcher:before {
  content: "\e913"; }

.ori-ant-design-container .anticon-rocket:before {
  content: "\e90f"; }

.ori-ant-design-container .anticon-dingding:before {
  content: "\e923"; }

.ori-ant-design-container .anticon-dingding-o:before {
  content: "\e925"; }

.ori-ant-design-container .anticon-bell:before {
  content: "\e64e"; }

.ori-ant-design-container .anticon-disconnect:before {
  content: "\e64f"; }

.ori-ant-design-container .anticon-database:before {
  content: "\e650"; }

.ori-ant-design-container .anticon-compass:before {
  content: "\e6db"; }

.ori-ant-design-container .anticon-barcode:before {
  content: "\e652"; }

.ori-ant-design-container .anticon-hourglass:before {
  content: "\e653"; }

.ori-ant-design-container .anticon-key:before {
  content: "\e654"; }

.ori-ant-design-container .anticon-flag:before {
  content: "\e655"; }

.ori-ant-design-container .anticon-layout:before {
  content: "\e656"; }

.ori-ant-design-container .anticon-login:before {
  content: "\e657"; }

.ori-ant-design-container .anticon-printer:before {
  content: "\e673"; }

.ori-ant-design-container .anticon-sound:before {
  content: "\e6e9"; }

.ori-ant-design-container .anticon-usb:before {
  content: "\e6d7"; }

.ori-ant-design-container .anticon-skin:before {
  content: "\e6d8"; }

.ori-ant-design-container .anticon-tool:before {
  content: "\e6d9"; }

.ori-ant-design-container .anticon-sync:before {
  content: "\e6da"; }

.ori-ant-design-container .anticon-wifi:before {
  content: "\e6d6"; }

.ori-ant-design-container .anticon-car:before {
  content: "\e6dc"; }

.ori-ant-design-container .anticon-copyright:before {
  content: "\e6de"; }

.ori-ant-design-container .anticon-schedule:before {
  content: "\e6df"; }

.ori-ant-design-container .anticon-user-add:before {
  content: "\e6ed"; }

.ori-ant-design-container .anticon-user-delete:before {
  content: "\e6e0"; }

.ori-ant-design-container .anticon-usergroup-add:before {
  content: "\e6dd"; }

.ori-ant-design-container .anticon-usergroup-delete:before {
  content: "\e6e1"; }

.ori-ant-design-container .anticon-man:before {
  content: "\e6e2"; }

.ori-ant-design-container .anticon-woman:before {
  content: "\e6ec"; }

.ori-ant-design-container .anticon-shop:before {
  content: "\e6e3"; }

.ori-ant-design-container .anticon-gift:before {
  content: "\e6e4"; }

.ori-ant-design-container .anticon-idcard:before {
  content: "\e6e5"; }

.ori-ant-design-container .anticon-medicine-box:before {
  content: "\e6e6"; }

.ori-ant-design-container .anticon-red-envelope:before {
  content: "\e6e7"; }

.ori-ant-design-container .anticon-coffee:before {
  content: "\e6e8"; }

.ori-ant-design-container .anticon-trademark:before {
  content: "\e651"; }

.ori-ant-design-container .anticon-safety:before {
  content: "\e6ea"; }

.ori-ant-design-container .anticon-wallet:before {
  content: "\e6eb"; }

.ori-ant-design-container .anticon-bank:before {
  content: "\e6ee"; }

.ori-ant-design-container .anticon-trophy:before {
  content: "\e6ef"; }

.ori-ant-design-container .anticon-contacts:before {
  content: "\e6f0"; }

.ori-ant-design-container .anticon-global:before {
  content: "\e6f1"; }

.ori-ant-design-container .anticon-shake:before {
  content: "\e94f"; }

.ori-ant-design-container .anticon-fork:before {
  content: "\e6f2"; }

.ori-ant-design-container .anticon-dashboard:before {
  content: "\e99a"; }

.ori-ant-design-container .anticon-profile:before {
  content: "\e999"; }

.ori-ant-design-container .anticon-table:before {
  content: "\e998"; }

.ori-ant-design-container .anticon-warning:before {
  content: "\e997"; }

.ori-ant-design-container .anticon-form:before {
  content: "\e996"; }

.ori-ant-design-container .anticon-spin:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear; }

.ori-ant-design-container .anticon-weibo-square:before {
  content: "\e6f5"; }

.ori-ant-design-container .anticon-weibo-circle:before {
  content: "\e6f4"; }

.ori-ant-design-container .anticon-taobao-circle:before {
  content: "\e6f3"; }

.ori-ant-design-container .anticon-html5:before {
  content: "\e9c7"; }

.ori-ant-design-container .anticon-weibo:before {
  content: "\e9c6"; }

.ori-ant-design-container .anticon-twitter:before {
  content: "\e9c5"; }

.ori-ant-design-container .anticon-wechat:before {
  content: "\e9c4"; }

.ori-ant-design-container .anticon-youtube:before {
  content: "\e9c3"; }

.ori-ant-design-container .anticon-alipay-circle:before {
  content: "\e9c2"; }

.ori-ant-design-container .anticon-taobao:before {
  content: "\e9c1"; }

.ori-ant-design-container .anticon-skype:before {
  content: "\e9c0"; }

.ori-ant-design-container .anticon-qq:before {
  content: "\e9bf"; }

.ori-ant-design-container .anticon-medium-workmark:before {
  content: "\e9be"; }

.ori-ant-design-container .anticon-gitlab:before {
  content: "\e9bd"; }

.ori-ant-design-container .anticon-medium:before {
  content: "\e9bc"; }

.ori-ant-design-container .anticon-linkedin:before {
  content: "\e9bb"; }

.ori-ant-design-container .anticon-google-plus:before {
  content: "\e9ba"; }

.ori-ant-design-container .anticon-dropbox:before {
  content: "\e9b9"; }

.ori-ant-design-container .anticon-facebook:before {
  content: "\e9b8"; }

.ori-ant-design-container .anticon-codepen:before {
  content: "\e9b7"; }

.ori-ant-design-container .anticon-amazon:before {
  content: "\e9b6"; }

.ori-ant-design-container .anticon-google:before {
  content: "\e9b5"; }

.ori-ant-design-container .anticon-codepen-circle:before {
  content: "\e9b4"; }

.ori-ant-design-container .anticon-alipay:before {
  content: "\e9b3"; }

.ori-ant-design-container .anticon-ant-design:before {
  content: "\e9b2"; }

.ori-ant-design-container .anticon-aliyun:before {
  content: "\e9f4"; }

.ori-ant-design-container .anticon-zhihu:before {
  content: "\e703"; }

.ori-ant-design-container .anticon-file-markdown:before {
  content: "\e704"; }

.ori-ant-design-container .anticon-slack:before {
  content: "\e705"; }

.ori-ant-design-container .anticon-slack-square:before {
  content: "\e706"; }

.ori-ant-design-container .anticon-behance:before {
  content: "\e707"; }

.ori-ant-design-container .anticon-behance-square:before {
  content: "\e708"; }

.ori-ant-design-container .anticon-dribbble:before {
  content: "\e709"; }

.ori-ant-design-container .anticon-dribbble-square:before {
  content: "\e70a"; }

.ori-ant-design-container .anticon-instagram:before {
  content: "\e70b"; }

.ori-ant-design-container .anticon-yuque:before {
  content: "\e70c"; }

.ori-ant-design-container .fade-enter,
.ori-ant-design-container .fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .fade-enter.fade-enter-active,
.ori-ant-design-container .fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .fade-enter,
.ori-ant-design-container .fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.ori-ant-design-container .fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes antFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes antFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ori-ant-design-container .move-up-enter,
.ori-ant-design-container .move-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-up-enter.move-up-enter-active,
.ori-ant-design-container .move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-up-enter,
.ori-ant-design-container .move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-down-enter,
.ori-ant-design-container .move-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-down-enter.move-down-enter-active,
.ori-ant-design-container .move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-down-enter,
.ori-ant-design-container .move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-left-enter,
.ori-ant-design-container .move-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-left-enter.move-left-enter-active,
.ori-ant-design-container .move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-left-enter,
.ori-ant-design-container .move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-right-enter,
.ori-ant-design-container .move-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .move-right-enter.move-right-enter-active,
.ori-ant-design-container .move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-right-enter,
.ori-ant-design-container .move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; } }

@keyframes antMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; } }

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; } }

@keyframes antMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; } }

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; } }

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; } }

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }

@-webkit-keyframes antMoveRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes antMoveRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }

@keyframes antMoveRightOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; } }

@keyframes antMoveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; } }

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; } }

@keyframes antMoveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; } }

@-webkit-keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.ori-ant-design-container .slide-up-enter,
.ori-ant-design-container .slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-up-enter.slide-up-enter-active,
.ori-ant-design-container .slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-up-enter,
.ori-ant-design-container .slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-down-enter,
.ori-ant-design-container .slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-down-enter.slide-down-enter-active,
.ori-ant-design-container .slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-down-enter,
.ori-ant-design-container .slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-left-enter,
.ori-ant-design-container .slide-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-left-enter.slide-left-enter-active,
.ori-ant-design-container .slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-left-enter,
.ori-ant-design-container .slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-right-enter,
.ori-ant-design-container .slide-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .slide-right-enter.slide-right-enter-active,
.ori-ant-design-container .slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-right-enter,
.ori-ant-design-container .slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes antSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes antSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes antSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@keyframes antSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@-webkit-keyframes antSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes antSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes antSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@keyframes antSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@-webkit-keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@-webkit-keyframes antSlideRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes antSlideRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes antSlideRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@keyframes antSlideRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

.ori-ant-design-container .swing-enter,
.ori-ant-design-container .swing-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .swing-enter.swing-enter-active,
.ori-ant-design-container .swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
  animation-name: antSwingIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@-webkit-keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  80% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

@keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  80% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

.ori-ant-design-container .zoom-enter,
.ori-ant-design-container .zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-enter.zoom-enter-active,
.ori-ant-design-container .zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-enter,
.ori-ant-design-container .zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-big-enter,
.ori-ant-design-container .zoom-big-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-enter.zoom-big-enter-active,
.ori-ant-design-container .zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-big-enter,
.ori-ant-design-container .zoom-big-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-big-fast-enter,
.ori-ant-design-container .zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-fast-enter.zoom-big-fast-enter-active,
.ori-ant-design-container .zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-big-fast-enter,
.ori-ant-design-container .zoom-big-fast-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-up-enter,
.ori-ant-design-container .zoom-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-up-enter.zoom-up-enter-active,
.ori-ant-design-container .zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-up-enter,
.ori-ant-design-container .zoom-up-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-down-enter,
.ori-ant-design-container .zoom-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-down-enter.zoom-down-enter-active,
.ori-ant-design-container .zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-down-enter,
.ori-ant-design-container .zoom-down-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-left-enter,
.ori-ant-design-container .zoom-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-left-enter.zoom-left-enter-active,
.ori-ant-design-container .zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-left-enter,
.ori-ant-design-container .zoom-left-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-right-enter,
.ori-ant-design-container .zoom-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-right-enter.zoom-right-enter-active,
.ori-ant-design-container .zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ori-ant-design-container .zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-right-enter,
.ori-ant-design-container .zoom-right-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

@-webkit-keyframes antZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); } }

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); } }

@-webkit-keyframes antZoomBigIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomUpOut {
  0% {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomRightOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

.ori-ant-design-container .ant-motion-collapse {
  overflow: hidden; }

.ori-ant-design-container .ant-motion-collapse-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }

.ori-ant-design-container {
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
  /*
    * import ant-design component css here, when ever you use any ant design component in chatbot or chatbot-message-types library.
    * also check antd folder in node_modules. if required component is dependent on other component then add other component css 
    * eq: i have used table in chatbot-message-types so i have used table component css. but table component is using pagination component and tooltip component so i have also added css for both pagination and tooltip even i am not using pagination any where seperately. 
    */ }
  .ori-ant-design-container .ant-btn {
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn > .anticon {
    line-height: 1; }
  .ori-ant-design-container .ant-btn,
  .ori-ant-design-container .ant-btn:active,
  .ori-ant-design-container .ant-btn:focus {
    outline: 0; }
  .ori-ant-design-container .ant-btn:not([disabled]):hover {
    text-decoration: none; }
  .ori-ant-design-container .ant-btn:not([disabled]):active {
    outline: 0;
    transition: none; }
  .ori-ant-design-container .ant-btn.disabled,
  .ori-ant-design-container .ant-btn[disabled] {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-btn.disabled > *,
  .ori-ant-design-container .ant-btn[disabled] > * {
    pointer-events: none; }
  .ori-ant-design-container .ant-btn-lg {
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px;
    height: 40px; }
  .ori-ant-design-container .ant-btn-sm {
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    height: 24px; }
  .ori-ant-design-container .ant-btn > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn:hover,
  .ori-ant-design-container .ant-btn:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn:hover > a:only-child,
  .ori-ant-design-container .ant-btn:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn:active,
  .ori-ant-design-container .ant-btn.active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn:active > a:only-child,
  .ori-ant-design-container .ant-btn.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn:active > a:only-child:after,
  .ori-ant-design-container .ant-btn.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn.disabled,
  .ori-ant-design-container .ant-btn[disabled],
  .ori-ant-design-container .ant-btn.disabled:hover,
  .ori-ant-design-container .ant-btn[disabled]:hover,
  .ori-ant-design-container .ant-btn.disabled:focus,
  .ori-ant-design-container .ant-btn[disabled]:focus,
  .ori-ant-design-container .ant-btn.disabled:active,
  .ori-ant-design-container .ant-btn[disabled]:active,
  .ori-ant-design-container .ant-btn.disabled.active,
  .ori-ant-design-container .ant-btn[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn.disabled > a:only-child,
  .ori-ant-design-container .ant-btn[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn:hover,
  .ori-ant-design-container .ant-btn:focus,
  .ori-ant-design-container .ant-btn:active,
  .ori-ant-design-container .ant-btn.active {
    background: #fff; }
  .ori-ant-design-container .ant-btn > i,
  .ori-ant-design-container .ant-btn > span {
    pointer-events: none; }
  .ori-ant-design-container .ant-btn-primary {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff; }
  .ori-ant-design-container .ant-btn-primary > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-primary:hover,
  .ori-ant-design-container .ant-btn-primary:focus {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-primary:hover > a:only-child,
  .ori-ant-design-container .ant-btn-primary:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-primary:active,
  .ori-ant-design-container .ant-btn-primary.active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn-primary:active > a:only-child,
  .ori-ant-design-container .ant-btn-primary.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-primary.disabled,
  .ori-ant-design-container .ant-btn-primary[disabled],
  .ori-ant-design-container .ant-btn-primary.disabled:hover,
  .ori-ant-design-container .ant-btn-primary[disabled]:hover,
  .ori-ant-design-container .ant-btn-primary.disabled:focus,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus,
  .ori-ant-design-container .ant-btn-primary.disabled:active,
  .ori-ant-design-container .ant-btn-primary[disabled]:active,
  .ori-ant-design-container .ant-btn-primary.disabled.active,
  .ori-ant-design-container .ant-btn-primary[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-primary.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-primary.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-primary.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-primary.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-primary.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-primary[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ori-ant-design-container .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-ghost {
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-ghost > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost:hover,
  .ori-ant-design-container .ant-btn-ghost:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-ghost:hover > a:only-child,
  .ori-ant-design-container .ant-btn-ghost:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost:active,
  .ori-ant-design-container .ant-btn-ghost.active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn-ghost:active > a:only-child,
  .ori-ant-design-container .ant-btn-ghost.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost.disabled,
  .ori-ant-design-container .ant-btn-ghost[disabled],
  .ori-ant-design-container .ant-btn-ghost.disabled:hover,
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover,
  .ori-ant-design-container .ant-btn-ghost.disabled:focus,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus,
  .ori-ant-design-container .ant-btn-ghost.disabled:active,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active,
  .ori-ant-design-container .ant-btn-ghost.disabled.active,
  .ori-ant-design-container .ant-btn-ghost[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-ghost.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-ghost.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-ghost.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-ghost.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-ghost.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-ghost[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dashed {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: dashed; }
  .ori-ant-design-container .ant-btn-dashed > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dashed:hover,
  .ori-ant-design-container .ant-btn-dashed:focus {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-dashed:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dashed:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dashed:active,
  .ori-ant-design-container .ant-btn-dashed.active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn-dashed:active > a:only-child,
  .ori-ant-design-container .ant-btn-dashed.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dashed.disabled,
  .ori-ant-design-container .ant-btn-dashed[disabled],
  .ori-ant-design-container .ant-btn-dashed.disabled:hover,
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover,
  .ori-ant-design-container .ant-btn-dashed.disabled:focus,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus,
  .ori-ant-design-container .ant-btn-dashed.disabled:active,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active,
  .ori-ant-design-container .ant-btn-dashed.disabled.active,
  .ori-ant-design-container .ant-btn-dashed[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-dashed.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dashed.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dashed.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dashed.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-dashed.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-dashed[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-danger {
    color: #f5222d;
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-danger > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-danger:hover,
  .ori-ant-design-container .ant-btn-danger:focus {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f; }
  .ori-ant-design-container .ant-btn-danger:hover > a:only-child,
  .ori-ant-design-container .ant-btn-danger:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-danger:active,
  .ori-ant-design-container .ant-btn-danger.active {
    color: #fff;
    background-color: #cf1322;
    border-color: #cf1322; }
  .ori-ant-design-container .ant-btn-danger:active > a:only-child,
  .ori-ant-design-container .ant-btn-danger.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-danger.disabled,
  .ori-ant-design-container .ant-btn-danger[disabled],
  .ori-ant-design-container .ant-btn-danger.disabled:hover,
  .ori-ant-design-container .ant-btn-danger[disabled]:hover,
  .ori-ant-design-container .ant-btn-danger.disabled:focus,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus,
  .ori-ant-design-container .ant-btn-danger.disabled:active,
  .ori-ant-design-container .ant-btn-danger[disabled]:active,
  .ori-ant-design-container .ant-btn-danger.disabled.active,
  .ori-ant-design-container .ant-btn-danger[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-danger.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-danger.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-danger.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-danger.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-danger.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-danger[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-circle,
  .ori-ant-design-container .ant-btn-circle-outline {
    width: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 50%;
    height: 32px; }
  .ori-ant-design-container .ant-btn-circle.ant-btn-lg,
  .ori-ant-design-container .ant-btn-circle-outline.ant-btn-lg {
    width: 40px;
    padding: 0;
    font-size: 18px;
    border-radius: 50%;
    height: 40px; }
  .ori-ant-design-container .ant-btn-circle.ant-btn-sm,
  .ori-ant-design-container .ant-btn-circle-outline.ant-btn-sm {
    width: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
    height: 24px; }
  .ori-ant-design-container .ant-btn:before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #fff;
    opacity: 0.35;
    content: '';
    border-radius: inherit;
    z-index: 1;
    transition: opacity .2s;
    pointer-events: none;
    display: none; }
  .ori-ant-design-container .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-btn.ant-btn-loading:before {
    display: block; }
  .ori-ant-design-container .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 29px;
    pointer-events: none;
    position: relative; }
  .ori-ant-design-container .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-left: -14px; }
  .ori-ant-design-container .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 24px; }
  .ori-ant-design-container .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-left: -17px; }
  .ori-ant-design-container .ant-btn-group {
    position: relative;
    display: inline-block; }
  .ori-ant-design-container .ant-btn-group > .ant-btn {
    position: relative;
    line-height: 30px; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:hover,
  .ori-ant-design-container .ant-btn-group > .ant-btn:focus,
  .ori-ant-design-container .ant-btn-group > .ant-btn:active,
  .ori-ant-design-container .ant-btn-group > .ant-btn.active {
    z-index: 2; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:disabled {
    z-index: 0; }
  .ori-ant-design-container .ant-btn-group-lg > .ant-btn {
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
    line-height: 38px; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn {
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    height: 24px;
    line-height: 22px; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn > .anticon {
    font-size: 14px; }
  .ori-ant-design-container .ant-btn-group .ant-btn + .ant-btn,
  .ori-ant-design-container .ant-btn + .ant-btn-group,
  .ori-ant-design-container .ant-btn-group span + .ant-btn,
  .ori-ant-design-container .ant-btn-group .ant-btn + span,
  .ori-ant-design-container .ant-btn-group + .ant-btn,
  .ori-ant-design-container .ant-btn-group + .ant-btn-group {
    margin-left: -1px; }
  .ori-ant-design-container .ant-btn-group .ant-btn:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:first-child,
  .ori-ant-design-container .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ori-ant-design-container .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group {
    float: left; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px; }
  .ori-ant-design-container .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding-left: 8px;
    padding-right: 8px; }
  .ori-ant-design-container .ant-btn:focus > span,
  .ori-ant-design-container .ant-btn:active > span {
    position: relative; }
  .ori-ant-design-container .ant-btn > .anticon + span,
  .ori-ant-design-container .ant-btn > span + .anticon {
    margin-left: 8px; }
  .ori-ant-design-container .ant-btn-clicked:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: inherit;
    border: 0 solid #1890ff;
    opacity: 0.4;
    -webkit-animation: buttonEffect .4s;
    animation: buttonEffect .4s;
    display: block; }
  .ori-ant-design-container .ant-btn-danger.ant-btn-clicked:after {
    border-color: #f5222d; }
  .ori-ant-design-container .ant-btn-background-ghost {
    background: transparent !important;
    border-color: #fff;
    color: #fff; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary {
    color: #1890ff;
    background-color: transparent;
    border-color: #1890ff; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled.active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger {
    color: #f5222d;
    background-color: transparent;
    border-color: #f5222d; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff4d4f;
    background-color: transparent;
    border-color: #ff4d4f; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.active {
    color: #cf1322;
    background-color: transparent;
    border-color: #cf1322; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled.active,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .ori-ant-design-container .ant-btn-two-chinese-chars:first-letter {
    letter-spacing: .34em; }
  .ori-ant-design-container .ant-btn-two-chinese-chars > * {
    letter-spacing: .34em;
    margin-right: -0.34em; }

@-webkit-keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }

@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }
  .ori-ant-design-container a.ant-btn {
    line-height: 30px; }
  .ori-ant-design-container a.ant-btn-lg {
    line-height: 38px; }
  .ori-ant-design-container a.ant-btn-sm {
    line-height: 22px; }
  .ori-ant-design-container .ant-badge {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    line-height: 1;
    vertical-align: middle; }
  .ori-ant-design-container .ant-badge-count {
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -10px;
    height: 20px;
    border-radius: 10px;
    min-width: 20px;
    background: #f5222d;
    color: #fff;
    line-height: 20px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    -webkit-transform-origin: -10% center;
    transform-origin: -10% center;
    box-shadow: 0 0 0 1px #fff; }
  .ori-ant-design-container .ant-badge-count a,
  .ori-ant-design-container .ant-badge-count a:hover {
    color: #fff; }
  .ori-ant-design-container .ant-badge-multiple-words {
    padding: 0 8px; }
  .ori-ant-design-container .ant-badge-dot {
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center;
    top: -3px;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: #f5222d;
    z-index: 10;
    box-shadow: 0 0 0 1px #fff; }
  .ori-ant-design-container .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline; }
  .ori-ant-design-container .ant-badge-status-dot {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    top: -1px; }
  .ori-ant-design-container .ant-badge-status-success {
    background-color: #52c41a; }
  .ori-ant-design-container .ant-badge-status-processing {
    background-color: #1890ff;
    position: relative; }
  .ori-ant-design-container .ant-badge-status-processing:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #1890ff;
    content: '';
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
    animation: antStatusProcessing 1.2s infinite ease-in-out; }
  .ori-ant-design-container .ant-badge-status-default {
    background-color: #d9d9d9; }
  .ori-ant-design-container .ant-badge-status-error {
    background-color: #f5222d; }
  .ori-ant-design-container .ant-badge-status-warning {
    background-color: #faad14; }
  .ori-ant-design-container .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin-left: 8px; }
  .ori-ant-design-container .ant-badge-zoom-appear,
  .ori-ant-design-container .ant-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .ori-ant-design-container .ant-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-scroll-number {
    top: auto;
    display: block;
    position: relative;
    -webkit-transform: none !important;
    transform: none !important; }

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }
  .ori-ant-design-container .ant-scroll-number {
    overflow: hidden; }
  .ori-ant-design-container .ant-scroll-number-only {
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 20px; }
  .ori-ant-design-container .ant-scroll-number-only > p {
    height: 20px;
    margin: 0; }

@-webkit-keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%); }
  100% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%); } }

@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%); }
  100% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%); } }

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%); } }

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%); } }
  .ori-ant-design-container .ant-form {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .ori-ant-design-container .ant-form legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: inherit;
    color: rgba(0, 0, 0, 0.45);
    border: 0;
    border-bottom: 1px solid #d9d9d9; }
  .ori-ant-design-container .ant-form label {
    font-size: 14px; }
  .ori-ant-design-container .ant-form input[type="search"] {
    box-sizing: border-box; }
  .ori-ant-design-container .ant-form input[type="radio"],
  .ori-ant-design-container .ant-form input[type="checkbox"] {
    line-height: normal; }
  .ori-ant-design-container .ant-form input[type="file"] {
    display: block; }
  .ori-ant-design-container .ant-form input[type="range"] {
    display: block;
    width: 100%; }
  .ori-ant-design-container .ant-form select[multiple],
  .ori-ant-design-container .ant-form select[size] {
    height: auto; }
  .ori-ant-design-container .ant-form input[type="file"]:focus,
  .ori-ant-design-container .ant-form input[type="radio"]:focus,
  .ori-ant-design-container .ant-form input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ori-ant-design-container .ant-form output {
    display: block;
    padding-top: 15px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-form-item-required:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d; }
  .ori-ant-design-container .ant-form-hide-required-mark .ant-form-item-required:before {
    display: none; }
  .ori-ant-design-container input[type="radio"][disabled],
  .ori-ant-design-container input[type="checkbox"][disabled],
  .ori-ant-design-container input[type="radio"].disabled,
  .ori-ant-design-container input[type="checkbox"].disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio-inline.disabled,
  .ori-ant-design-container .ant-radio-vertical.disabled,
  .ori-ant-design-container .ant-checkbox-inline.disabled,
  .ori-ant-design-container .ant-checkbox-vertical.disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio.disabled label,
  .ori-ant-design-container .ant-checkbox.disabled label {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-form-item {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 24px;
    vertical-align: top;
    transition: margin 0.15s steps(1); }
  .ori-ant-design-container .ant-form-item label {
    position: relative; }
  .ori-ant-design-container .ant-form-item label > .anticon {
    vertical-align: top;
    font-size: 14px; }
  .ori-ant-design-container .ant-form-item-control > .ant-form-item:last-child,
  .ori-ant-design-container .ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
    margin-bottom: -24px; }
  .ori-ant-design-container .ant-form-item-control {
    line-height: 39.9999px;
    position: relative;
    zoom: 1; }
  .ori-ant-design-container .ant-form-item-control:before,
  .ori-ant-design-container .ant-form-item-control:after {
    content: " ";
    display: table; }
  .ori-ant-design-container .ant-form-item-control:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; }
  .ori-ant-design-container .ant-form-item-children {
    position: relative; }
  .ori-ant-design-container .ant-form-item-with-help {
    margin-bottom: 5px;
    transition: none; }
  .ori-ant-design-container .ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    line-height: 39.9999px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap; }
  .ori-ant-design-container .ant-form-item-label label {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-form-item-label label:after {
    content: ":";
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px; }
  .ori-ant-design-container .ant-form-item .ant-switch {
    margin: 2px 0 4px; }
  .ori-ant-design-container .ant-form-item-no-colon .ant-form-item-label label:after {
    content: " "; }
  .ori-ant-design-container .ant-form-explain,
  .ori-ant-design-container .ant-form-extra {
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.5;
    transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    margin-top: -2px; }
  .ori-ant-design-container .ant-form-extra {
    padding-top: 4px; }
  .ori-ant-design-container .ant-form-text {
    display: inline-block;
    padding-right: 8px; }
  .ori-ant-design-container .ant-form-split {
    display: block;
    text-align: center; }
  .ori-ant-design-container form .has-feedback .ant-input {
    padding-right: 24px; }
  .ori-ant-design-container form .has-feedback > .ant-select .ant-select-arrow,
  .ori-ant-design-container form .has-feedback > .ant-select .ant-select-selection__clear,
  .ori-ant-design-container form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ori-ant-design-container form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
    right: 28px; }
  .ori-ant-design-container form .has-feedback > .ant-select .ant-select-selection-selected-value,
  .ori-ant-design-container form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
    padding-right: 42px; }
  .ori-ant-design-container form .has-feedback .ant-cascader-picker-arrow {
    margin-right: 17px; }
  .ori-ant-design-container form .has-feedback .ant-cascader-picker-clear {
    right: 28px; }
  .ori-ant-design-container form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: 28px; }
  .ori-ant-design-container form .has-feedback .ant-calendar-picker-icon,
  .ori-ant-design-container form .has-feedback .ant-time-picker-icon,
  .ori-ant-design-container form .has-feedback .ant-calendar-picker-clear,
  .ori-ant-design-container form .has-feedback .ant-time-picker-clear {
    right: 28px; }
  .ori-ant-design-container form textarea.ant-input {
    height: auto; }
  .ori-ant-design-container form .ant-upload {
    background: transparent; }
  .ori-ant-design-container form input[type="radio"],
  .ori-ant-design-container form input[type="checkbox"] {
    width: 14px;
    height: 14px; }
  .ori-ant-design-container form .ant-radio-inline,
  .ori-ant-design-container form .ant-checkbox-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
    margin-left: 8px; }
  .ori-ant-design-container form .ant-radio-inline:first-child,
  .ori-ant-design-container form .ant-checkbox-inline:first-child {
    margin-left: 0; }
  .ori-ant-design-container form .ant-checkbox-vertical,
  .ori-ant-design-container form .ant-radio-vertical {
    display: block; }
  .ori-ant-design-container form .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ori-ant-design-container form .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0; }
  .ori-ant-design-container form .ant-input-number + .ant-form-text {
    margin-left: 8px; }
  .ori-ant-design-container form .ant-select,
  .ori-ant-design-container form .ant-cascader-picker {
    width: 100%; }
  .ori-ant-design-container form .ant-input-group .ant-select,
  .ori-ant-design-container form .ant-input-group .ant-cascader-picker {
    width: auto; }
  .ori-ant-design-container form .ant-input-group-wrapper {
    vertical-align: middle;
    position: relative;
    top: -1px; }
  .ori-ant-design-container .ant-input-group-wrap .ant-select-selection {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .ori-ant-design-container .ant-input-group-wrap .ant-select-selection:hover {
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-input-group-wrap .ant-select-selection--single {
    margin-left: -1px;
    height: 40px;
    background-color: #eee; }
  .ori-ant-design-container .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
    padding-left: 8px;
    padding-right: 25px;
    line-height: 30px; }
  .ori-ant-design-container .ant-input-group-wrap .ant-select-open .ant-select-selection {
    border-color: #d9d9d9;
    box-shadow: none; }
  .ori-ant-design-container .ant-form-vertical .ant-form-item-label,
  .ori-ant-design-container .ant-col-24.ant-form-item-label,
  .ori-ant-design-container .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5; }
  .ori-ant-design-container .ant-form-vertical .ant-form-item-label label:after,
  .ori-ant-design-container .ant-col-24.ant-form-item-label label:after,
  .ori-ant-design-container .ant-col-xl-24.ant-form-item-label label:after {
    display: none; }
  .ori-ant-design-container .ant-form-vertical .ant-form-item {
    padding-bottom: 8px; }
  .ori-ant-design-container .ant-form-vertical .ant-form-item-control {
    line-height: 1.5; }
  .ori-ant-design-container .ant-form-vertical .ant-form-explain,
  .ori-ant-design-container .ant-form-vertical .ant-form-extra {
    margin-top: 2px;
    margin-bottom: -4px; }
  @media (max-width: 575px) {
    .ori-ant-design-container .ant-form-item-label,
    .ori-ant-design-container .ant-form-item-control-wrapper {
      display: block;
      width: 100%; }
    .ori-ant-design-container .ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-form-item-label label:after {
      display: none; }
    .ori-ant-design-container .ant-col-xs-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-col-xs-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 767px) {
    .ori-ant-design-container .ant-col-sm-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-col-sm-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 991px) {
    .ori-ant-design-container .ant-col-md-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-col-md-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 1199px) {
    .ori-ant-design-container .ant-col-lg-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-col-lg-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 1599px) {
    .ori-ant-design-container .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5; }
    .ori-ant-design-container .ant-col-xl-24.ant-form-item-label label:after {
      display: none; } }
  .ori-ant-design-container .ant-form-inline .ant-form-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0; }
  .ori-ant-design-container .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px; }
  .ori-ant-design-container .ant-form-inline .ant-form-item > div {
    display: inline-block;
    vertical-align: middle; }
  .ori-ant-design-container .ant-form-inline .ant-form-text {
    display: inline-block; }
  .ori-ant-design-container .ant-form-inline .has-feedback {
    display: inline-block; }
  .ori-ant-design-container .ant-form-inline .ant-form-explain {
    position: absolute; }
  .ori-ant-design-container .has-success.has-feedback .ant-form-item-children:after,
  .ori-ant-design-container .has-warning.has-feedback .ant-form-item-children:after,
  .ori-ant-design-container .has-error.has-feedback .ant-form-item-children:after,
  .ori-ant-design-container .is-validating.has-feedback .ant-form-item-children:after {
    position: absolute;
    top: 50%;
    right: 0;
    visibility: visible;
    pointer-events: none;
    width: 32px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    text-align: center;
    font-size: 14px;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    z-index: 1; }
  .ori-ant-design-container .has-success.has-feedback .ant-form-item-children:after {
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
    content: '\e630';
    color: #52c41a; }
  .ori-ant-design-container .has-warning .ant-form-explain,
  .ori-ant-design-container .has-warning .ant-form-split {
    color: #faad14; }
  .ori-ant-design-container .has-warning .ant-input,
  .ori-ant-design-container .has-warning .ant-input:hover {
    border-color: #faad14; }
  .ori-ant-design-container .has-warning .ant-input:focus {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }
  .ori-ant-design-container .has-warning .ant-input:not([disabled]):hover {
    border-color: #faad14; }
  .ori-ant-design-container .has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }
  .ori-ant-design-container .has-warning .ant-input-prefix {
    color: #faad14; }
  .ori-ant-design-container .has-warning .ant-input-group-addon {
    color: #faad14;
    border-color: #faad14;
    background-color: #fff; }
  .ori-ant-design-container .has-warning .has-feedback {
    color: #faad14; }
  .ori-ant-design-container .has-warning.has-feedback .ant-form-item-children:after {
    content: '\e62c';
    color: #faad14;
    -webkit-animation-name: diffZoomIn3 !important;
    animation-name: diffZoomIn3 !important; }
  .ori-ant-design-container .has-warning .ant-select-selection {
    border-color: #faad14; }
  .ori-ant-design-container .has-warning .ant-select-open .ant-select-selection,
  .ori-ant-design-container .has-warning .ant-select-focused .ant-select-selection {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }
  .ori-ant-design-container .has-warning .ant-calendar-picker-icon:after,
  .ori-ant-design-container .has-warning .ant-time-picker-icon:after,
  .ori-ant-design-container .has-warning .ant-picker-icon:after,
  .ori-ant-design-container .has-warning .ant-select-arrow,
  .ori-ant-design-container .has-warning .ant-cascader-picker-arrow {
    color: #faad14; }
  .ori-ant-design-container .has-warning .ant-input-number,
  .ori-ant-design-container .has-warning .ant-time-picker-input {
    border-color: #faad14; }
  .ori-ant-design-container .has-warning .ant-input-number-focused,
  .ori-ant-design-container .has-warning .ant-time-picker-input-focused,
  .ori-ant-design-container .has-warning .ant-input-number:focus,
  .ori-ant-design-container .has-warning .ant-time-picker-input:focus {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }
  .ori-ant-design-container .has-warning .ant-input-number:not([disabled]):hover,
  .ori-ant-design-container .has-warning .ant-time-picker-input:not([disabled]):hover {
    border-color: #faad14; }
  .ori-ant-design-container .has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2); }
  .ori-ant-design-container .has-error .ant-form-explain,
  .ori-ant-design-container .has-error .ant-form-split {
    color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input,
  .ori-ant-design-container .has-error .ant-input:hover {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input:focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .has-error .ant-input:not([disabled]):hover {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .has-error .ant-input-prefix {
    color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input-group-addon {
    color: #f5222d;
    border-color: #f5222d;
    background-color: #fff; }
  .ori-ant-design-container .has-error .has-feedback {
    color: #f5222d; }
  .ori-ant-design-container .has-error.has-feedback .ant-form-item-children:after {
    content: '\e62e';
    color: #f5222d;
    -webkit-animation-name: diffZoomIn2 !important;
    animation-name: diffZoomIn2 !important; }
  .ori-ant-design-container .has-error .ant-select-selection {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-select-open .ant-select-selection,
  .ori-ant-design-container .has-error .ant-select-focused .ant-select-selection {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input-group-addon .ant-select-selection {
    border-color: transparent;
    box-shadow: none; }
  .ori-ant-design-container .has-error .ant-calendar-picker-icon:after,
  .ori-ant-design-container .has-error .ant-time-picker-icon:after,
  .ori-ant-design-container .has-error .ant-picker-icon:after,
  .ori-ant-design-container .has-error .ant-select-arrow,
  .ori-ant-design-container .has-error .ant-cascader-picker-arrow {
    color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input-number,
  .ori-ant-design-container .has-error .ant-time-picker-input {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-input-number-focused,
  .ori-ant-design-container .has-error .ant-time-picker-input-focused,
  .ori-ant-design-container .has-error .ant-input-number:focus,
  .ori-ant-design-container .has-error .ant-time-picker-input:focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .has-error .ant-input-number:not([disabled]):hover,
  .ori-ant-design-container .has-error .ant-time-picker-input:not([disabled]):hover {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-mention-wrapper .ant-mention-editor,
  .ori-ant-design-container .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
    border-color: #f5222d; }
  .ori-ant-design-container .has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
  .ori-ant-design-container .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .has-error .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2); }
  .ori-ant-design-container .is-validating.has-feedback .ant-form-item-children:after {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
    animation: loadingCircle 1s infinite linear;
    content: "\e64d";
    color: #1890ff; }
  .ori-ant-design-container .ant-advanced-search-form .ant-form-item {
    margin-bottom: 24px; }
  .ori-ant-design-container .show-help-enter,
  .ori-ant-design-container .show-help-appear {
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .ori-ant-design-container .show-help-leave {
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .ori-ant-design-container .show-help-enter.show-help-enter-active,
  .ori-ant-design-container .show-help-appear.show-help-appear-active {
    -webkit-animation-name: antShowHelpIn;
    animation-name: antShowHelpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .ori-ant-design-container .show-help-leave.show-help-leave-active {
    -webkit-animation-name: antShowHelpOut;
    animation-name: antShowHelpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .ori-ant-design-container .show-help-enter,
  .ori-ant-design-container .show-help-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .show-help-leave {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

@-webkit-keyframes antShowHelpIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes antShowHelpIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes antShowHelpOut {
  to {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@keyframes antShowHelpOut {
  to {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
  .ori-ant-design-container .ant-input {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s; }
  .ori-ant-design-container .ant-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .ori-ant-design-container .ant-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .ori-ant-design-container .ant-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .ori-ant-design-container .ant-input:hover {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-input:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .ori-ant-design-container .ant-input-disabled:hover {
    border-color: #e6d8d8; }
  .ori-ant-design-container textarea.ant-input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all .3s, height 0s;
    min-height: 32px; }
  .ori-ant-design-container .ant-input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .ori-ant-design-container .ant-input-sm {
    padding: 1px 7px;
    height: 24px; }
  .ori-ant-design-container .ant-input-group {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: table;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%; }
  .ori-ant-design-container .ant-input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0; }
  .ori-ant-design-container .ant-input-group > [class*="col-"] {
    padding-right: 8px; }
  .ori-ant-design-container .ant-input-group > [class*="col-"]:last-child {
    padding-right: 0; }
  .ori-ant-design-container .ant-input-group-addon,
  .ori-ant-design-container .ant-input-group-wrap,
  .ori-ant-design-container .ant-input-group > .ant-input {
    display: table-cell; }
  .ori-ant-design-container .ant-input-group-addon:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group-addon,
  .ori-ant-design-container .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle; }
  .ori-ant-design-container .ant-input-group-wrap > * {
    display: block !important; }
  .ori-ant-design-container .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0; }
  .ori-ant-design-container .ant-input-group .ant-input:focus {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group-addon {
    padding: 0 11px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    transition: all .3s; }
  .ori-ant-design-container .ant-input-group-addon .ant-select {
    margin: -5px -11px; }
  .ori-ant-design-container .ant-input-group-addon .ant-select .ant-select-selection {
    background-color: inherit;
    margin: -1px;
    border: 1px solid transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-input-group-addon .ant-select-open .ant-select-selection,
  .ori-ant-design-container .ant-input-group-addon .ant-select-focused .ant-select-selection {
    color: #1890ff; }
  .ori-ant-design-container .ant-input-group-addon > i:only-child:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:first-child,
  .ori-ant-design-container .ant-input-group-addon:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
  .ori-ant-design-container .ant-input-group-addon:first-child .ant-select .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .ori-ant-design-container .ant-input-group-addon:first-child {
    border-right: 0; }
  .ori-ant-design-container .ant-input-group-addon:last-child {
    border-left: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:last-child,
  .ori-ant-design-container .ant-input-group-addon:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
  .ori-ant-design-container .ant-input-group-addon:last-child .ant-select .ant-select-selection {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .ori-ant-design-container .ant-input-group-lg .ant-input,
  .ori-ant-design-container .ant-input-group-lg > .ant-input-group-addon {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .ori-ant-design-container .ant-input-group-sm .ant-input,
  .ori-ant-design-container .ant-input-group-sm > .ant-input-group-addon {
    padding: 1px 7px;
    height: 24px; }
  .ori-ant-design-container .ant-input-group-lg .ant-select-selection--single {
    height: 40px; }
  .ori-ant-design-container .ant-input-group-sm .ant-select-selection--single {
    height: 24px; }
  .ori-ant-design-container .ant-input-group .ant-input-affix-wrapper {
    display: table-cell;
    width: 100%;
    float: left; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact {
    display: block;
    zoom: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact:before,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact:after {
    content: " ";
    display: table; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > * {
    border-radius: 0;
    border-right-width: 0;
    vertical-align: top;
    float: none;
    display: inline-block; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
    z-index: auto; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-radius: 0;
    border-right-width: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > *:first-child,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > *:last-child,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px; }
  .ori-ant-design-container .ant-input-group-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
  .ori-ant-design-container .ant-input-affix-wrapper {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input {
    z-index: 1; }
  .ori-ant-design-container .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input-prefix,
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    line-height: 0;
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input-prefix {
    left: 12px; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px; }
  .ori-ant-design-container .ant-input-affix-wrapper .ant-input {
    min-height: 100%; }
  .ori-ant-design-container .ant-input-search-icon {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
    right: 12px; }
  .ori-ant-design-container .ant-input-search > .ant-input-suffix > .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-input-search > .ant-input-suffix > .ant-input-search-button > .anticon-search {
    font-size: 16px; }
  .ori-ant-design-container .ant-input-search.ant-input-search-enter-button > .ant-input {
    padding-right: 46px; }
  .ori-ant-design-container .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
    right: 0; }
  .ori-ant-design-container .ant-carousel {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .ori-ant-design-container .ant-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .ori-ant-design-container .ant-carousel .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
  .ori-ant-design-container .ant-carousel .slick-list:focus {
    outline: none; }
  .ori-ant-design-container .ant-carousel .slick-list.dragging {
    cursor: pointer; }
  .ori-ant-design-container .ant-carousel .slick-slider .slick-track,
  .ori-ant-design-container .ant-carousel .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .ori-ant-design-container .ant-carousel .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-track:before,
  .ori-ant-design-container .ant-carousel .slick-track:after {
    content: "";
    display: table; }
  .ori-ant-design-container .ant-carousel .slick-track:after {
    clear: both; }
  .ori-ant-design-container .slick-loading .ant-carousel .slick-track {
    visibility: hidden; }
  .ori-ant-design-container .ant-carousel .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
  .ori-ant-design-container [dir="rtl"] .ant-carousel .slick-slide {
    float: right; }
  .ori-ant-design-container .ant-carousel .slick-slide img {
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-slide.slick-loading img {
    display: none; }
  .ori-ant-design-container .ant-carousel .slick-slide.dragging img {
    pointer-events: none; }
  .ori-ant-design-container .ant-carousel .slick-initialized .slick-slide {
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-loading .slick-slide {
    visibility: hidden; }
  .ori-ant-design-container .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .ori-ant-design-container .ant-carousel .slick-arrow.slick-hidden {
    display: none; }
  .ori-ant-design-container .ant-carousel .slick-prev,
  .ori-ant-design-container .ant-carousel .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: 0;
    outline: none; }
  .ori-ant-design-container .ant-carousel .slick-prev:hover,
  .ori-ant-design-container .ant-carousel .slick-next:hover,
  .ori-ant-design-container .ant-carousel .slick-prev:focus,
  .ori-ant-design-container .ant-carousel .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
  .ori-ant-design-container .ant-carousel .slick-prev:hover:before,
  .ori-ant-design-container .ant-carousel .slick-next:hover:before,
  .ori-ant-design-container .ant-carousel .slick-prev:focus:before,
  .ori-ant-design-container .ant-carousel .slick-next:focus:before {
    opacity: 1; }
  .ori-ant-design-container .ant-carousel .slick-prev.slick-disabled:before,
  .ori-ant-design-container .ant-carousel .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .ori-ant-design-container .ant-carousel .slick-prev {
    left: -25px; }
  .ori-ant-design-container .ant-carousel .slick-prev:before {
    content: "←"; }
  .ori-ant-design-container .ant-carousel .slick-next {
    right: -25px; }
  .ori-ant-design-container .ant-carousel .slick-next:before {
    content: "→"; }
  .ori-ant-design-container .ant-carousel .slick-dots {
    position: absolute;
    bottom: 12px;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 3px; }
  .ori-ant-design-container .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0 2px;
    padding: 0; }
  .ori-ant-design-container .ant-carousel .slick-dots li button {
    border: 0;
    cursor: pointer;
    background: #fff;
    opacity: 0.3;
    display: block;
    width: 16px;
    height: 3px;
    border-radius: 1px;
    outline: none;
    font-size: 0;
    color: transparent;
    transition: all .5s;
    padding: 0; }
  .ori-ant-design-container .ant-carousel .slick-dots li button:hover,
  .ori-ant-design-container .ant-carousel .slick-dots li button:focus {
    opacity: 0.75; }
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
    width: 24px; }
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active button:hover,
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active button:focus {
    opacity: 1; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots {
    width: 3px;
    bottom: auto;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: auto; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li {
    margin: 0 2px;
    vertical-align: baseline; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px; }
  .ori-ant-design-container .ant-upload {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    outline: 0; }
  .ori-ant-design-container .ant-upload p {
    margin: 0; }
  .ori-ant-design-container .ant-upload-btn {
    display: block;
    width: 100%;
    outline: none; }
  .ori-ant-design-container .ant-upload input[type="file"] {
    cursor: pointer; }
  .ori-ant-design-container .ant-upload.ant-upload-select {
    display: inline-block; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #d9d9d9;
    width: 104px;
    height: 104px;
    border-radius: 4px;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card > .ant-upload {
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 8px; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff; }
  .ori-ant-design-container .ant-upload.ant-upload-drag {
    border: 1px dashed #d9d9d9;
    transition: border-color 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px 0;
    background: #fafafa; }
  .ori-ant-design-container .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border: 2px dashed #40a9ff; }
  .ori-ant-design-container .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle; }
  .ori-ant-design-container .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 48px;
    color: #40a9ff; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 16px;
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload.ant-upload-drag .anticon-plus {
    font-size: 30px;
    transition: all .3s;
    color: rgba(0, 0, 0, 0.25); }
  .ori-ant-design-container .ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload-list {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    zoom: 1; }
  .ori-ant-design-container .ant-upload-list:before,
  .ori-ant-design-container .ant-upload-list:after {
    content: " ";
    display: table; }
  .ori-ant-design-container .ant-upload-list:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; }
  .ori-ant-design-container .ant-upload-list-item {
    margin-top: 8px;
    font-size: 14px;
    position: relative;
    height: 22px; }
  .ori-ant-design-container .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 22px;
    width: 100%;
    display: inline-block; }
  .ori-ant-design-container .ant-upload-list-item-info {
    height: 100%;
    padding: 0 12px 0 4px;
    transition: background-color 0.3s; }
  .ori-ant-design-container .ant-upload-list-item-info > span {
    display: block; }
  .ori-ant-design-container .ant-upload-list-item-info .anticon-loading,
  .ori-ant-design-container .ant-upload-list-item-info .anticon-paper-clip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 5px; }
  .ori-ant-design-container .ant-upload-list-item .anticon-cross {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    transition: all .3s;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px; }
  .ori-ant-design-container :root .ant-upload-list-item .anticon-cross {
    font-size: 12px; }
  .ori-ant-design-container .ant-upload-list-item .anticon-cross:hover {
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #e6f7ff; }
  .ori-ant-design-container .ant-upload-list-item:hover .anticon-cross {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-item-error,
  .ori-ant-design-container .ant-upload-list-item-error .anticon-paper-clip,
  .ori-ant-design-container .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #f5222d; }
  .ori-ant-design-container .ant-upload-list-item-error .anticon-cross {
    opacity: 1;
    color: #f5222d !important; }
  .ori-ant-design-container .ant-upload-list-item-progress {
    line-height: 0;
    font-size: 14px;
    position: absolute;
    width: 100%;
    bottom: -12px;
    padding-left: 26px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    height: 66px;
    position: relative; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item:hover,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: transparent; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-error,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #f5222d; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-info,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-uploading,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 8px;
    left: 8px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    width: 48px;
    height: 48px;
    display: block;
    overflow: hidden; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail.anticon:before,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail.anticon:before {
    line-height: 48px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0 8px;
    line-height: 44px;
    transition: all .3s;
    padding-left: 48px;
    padding-right: 8px;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-progress,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-left: 56px;
    margin-top: 0;
    bottom: 14px;
    width: calc(100% - 24px); }
  .ori-ant-design-container .ant-upload-list-picture .anticon-cross,
  .ori-ant-design-container .ant-upload-list-picture-card .anticon-cross {
    position: absolute;
    right: 8px;
    top: 8px;
    line-height: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card {
    display: inline; }
  .ori-ant-design-container .ant-upload-list-picture-card.ant-upload-list:after {
    display: none; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info {
    height: 100%;
    position: relative;
    overflow: hidden; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    content: ' ';
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all .3s;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    white-space: nowrap;
    opacity: 0;
    transition: all .3s; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    z-index: 10;
    transition: all .3s;
    cursor: pointer;
    font-size: 16px;
    width: 16px;
    color: rgba(255, 255, 255, 0.85);
    margin: 0 4px; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 100%;
    height: 100%;
    position: static; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    display: none; }
  .ori-ant-design-container .ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
    display: block; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
    display: none; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-left: 0;
    bottom: 32px; }
  .ori-ant-design-container .ant-upload-list .ant-upload-success-icon {
    color: #52c41a;
    font-weight: bold; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-enter,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-leave,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-enter,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-enter {
    -webkit-animation-name: uploadAnimateIn;
    animation-name: uploadAnimateIn; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-leave {
    -webkit-animation-name: uploadAnimateOut;
    animation-name: uploadAnimateOut; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
    animation-name: uploadAnimateInlineIn; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
    animation-name: uploadAnimateInlineOut; }

@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }
  .ori-ant-design-container .ant-avatar {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
    background: #ccc;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px; }
  .ori-ant-design-container .ant-avatar-image {
    background: transparent; }
  .ori-ant-design-container .ant-avatar > * {
    line-height: 32px; }
  .ori-ant-design-container .ant-avatar.ant-avatar-icon {
    font-size: 18px; }
  .ori-ant-design-container .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px; }
  .ori-ant-design-container .ant-avatar-lg > * {
    line-height: 40px; }
  .ori-ant-design-container .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px; }
  .ori-ant-design-container .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px; }
  .ori-ant-design-container .ant-avatar-sm > * {
    line-height: 24px; }
  .ori-ant-design-container .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px; }
  .ori-ant-design-container .ant-avatar-square {
    border-radius: 4px; }
  .ori-ant-design-container .ant-avatar > img {
    width: 100%;
    height: 100%;
    display: block; }
  .ori-ant-design-container .ant-select {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    position: relative; }
  .ori-ant-design-container .ant-select ul,
  .ori-ant-design-container .ant-select ol {
    margin: 0;
    padding: 0;
    list-style: none; }
  .ori-ant-design-container .ant-select > ul > li > a {
    padding: 0;
    background-color: #fff; }
  .ori-ant-design-container .ant-select-arrow {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    line-height: 1;
    margin-top: -6px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px; }
  .ori-ant-design-container .ant-select-arrow:before {
    display: block;
    font-family: "anticon" !important; }
  .ori-ant-design-container .ant-select-arrow * {
    display: none; }
  .ori-ant-design-container .ant-select-arrow:before {
    content: '\e61d';
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }
  .ori-ant-design-container .ant-select-selection {
    outline: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-select-selection:hover {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-select-focused .ant-select-selection,
  .ori-ant-design-container .ant-select-selection:focus,
  .ori-ant-design-container .ant-select-selection:active {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-select-selection__clear {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    opacity: 0;
    position: absolute;
    right: 11px;
    z-index: 1;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    transition: color 0.3s ease, opacity 0.15s ease; }
  .ori-ant-design-container .ant-select-selection__clear:before {
    display: block;
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e62e"; }
  .ori-ant-design-container .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1; }
  .ori-ant-design-container .ant-select-selection-selected-value {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding-right: 20px; }
  .ori-ant-design-container .ant-select-disabled {
    color: rgba(0, 0, 0, 0.25); }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection:hover,
  .ori-ant-design-container .ant-select-disabled .ant-select-selection:focus,
  .ori-ant-design-container .ant-select-disabled .ant-select-selection:active {
    border-color: #d9d9d9;
    box-shadow: none; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    background: #f5f5f5;
    color: #aaa;
    padding-right: 10px; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
    display: none; }
  .ori-ant-design-container .ant-select-selection--single {
    height: 32px;
    position: relative;
    cursor: pointer; }
  .ori-ant-design-container .ant-select-selection__rendered {
    display: block;
    margin-left: 11px;
    margin-right: 11px;
    position: relative;
    line-height: 30px; }
  .ori-ant-design-container .ant-select-selection__rendered:after {
    content: '.';
    visibility: hidden;
    pointer-events: none;
    display: inline-block;
    width: 0; }
  .ori-ant-design-container .ant-select-lg {
    font-size: 16px; }
  .ori-ant-design-container .ant-select-lg .ant-select-selection--single {
    height: 40px; }
  .ori-ant-design-container .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px; }
  .ori-ant-design-container .ant-select-lg .ant-select-selection--multiple {
    min-height: 40px; }
  .ori-ant-design-container .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 32px;
    line-height: 32px; }
  .ori-ant-design-container .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
    top: 20px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection--single {
    height: 24px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection__rendered {
    line-height: 22px;
    margin: 0 7px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection--multiple {
    min-height: 24px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
    height: 16px;
    line-height: 14px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
    top: 12px; }
  .ori-ant-design-container .ant-select-sm .ant-select-selection__clear,
  .ori-ant-design-container .ant-select-sm .ant-select-arrow {
    right: 8px; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, 0.25);
    cursor: default; }
  .ori-ant-design-container .ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.25); }
  .ori-ant-design-container .ant-select-search__field__wrap {
    display: inline-block;
    position: relative; }
  .ori-ant-design-container .ant-select-selection__placeholder,
  .ori-ant-design-container .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    left: 0;
    right: 9px;
    color: #bfbfbf;
    line-height: 20px;
    height: 20px;
    max-width: 100%;
    margin-top: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left; }
  .ori-ant-design-container .ant-select-search__field__placeholder {
    left: 12px; }
  .ori-ant-design-container .ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: -9999px;
    white-space: pre;
    pointer-events: none; }
  .ori-ant-design-container .ant-select-search--inline {
    position: absolute;
    height: 100%;
    width: 100%; }
  .ori-ant-design-container .ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%; }
  .ori-ant-design-container .ant-select-search--inline .ant-select-search__field {
    border-width: 0;
    font-size: 100%;
    height: 100%;
    width: 100%;
    background: transparent;
    outline: 0;
    border-radius: 4px;
    line-height: 1; }
  .ori-ant-design-container .ant-select-search--inline > i {
    float: right; }
  .ori-ant-design-container .ant-select-selection--multiple {
    min-height: 32px;
    cursor: text;
    padding-bottom: 3px;
    zoom: 1; }
  .ori-ant-design-container .ant-select-selection--multiple:before,
  .ori-ant-design-container .ant-select-selection--multiple:after {
    content: " ";
    display: table; }
  .ori-ant-design-container .ant-select-selection--multiple:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-search--inline {
    float: left;
    position: static;
    width: auto;
    padding: 0;
    max-width: 100%; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
    max-width: 100%;
    width: 0.75em; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-left: 5px;
    margin-bottom: -3px;
    height: auto; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px; }
  .ori-ant-design-container .ant-select-selection--multiple > ul > li,
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 3px;
    height: 24px;
    line-height: 22px; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    float: left;
    margin-right: 4px;
    max-width: 99%;
    position: relative;
    overflow: hidden;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 20px 0 10px; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    line-height: inherit;
    cursor: pointer;
    font-weight: bold;
    transition: all .3s;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    position: absolute;
    right: 4px; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__remove:before {
    display: block;
    font-family: "anticon" !important; }
  .ori-ant-design-container :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: #404040; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__choice__remove:before {
    content: "\e633"; }
  .ori-ant-design-container .ant-select-selection--multiple .ant-select-selection__clear {
    top: 16px; }
  .ori-ant-design-container .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 20px; }
  .ori-ant-design-container .ant-select-open .ant-select-arrow:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .ori-ant-design-container .ant-select-open .ant-select-selection {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-select-combobox .ant-select-arrow {
    display: none; }
  .ori-ant-design-container .ant-select-combobox .ant-select-search--inline {
    height: 100%;
    width: 100%;
    float: none; }
  .ori-ant-design-container .ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%; }
  .ori-ant-design-container .ant-select-combobox .ant-select-search__field {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: none; }
  .ori-ant-design-container .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-right: 20px; }
  .ori-ant-design-container .ant-select-dropdown {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1050;
    left: -9999px;
    top: -9999px;
    position: absolute;
    outline: none;
    font-size: 14px; }
  .ori-ant-design-container .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ori-ant-design-container .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .ori-ant-design-container .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ori-ant-design-container .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .ori-ant-design-container .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .ori-ant-design-container .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .ori-ant-design-container .ant-select-dropdown-hidden {
    display: none; }
  .ori-ant-design-container .ant-select-dropdown-menu {
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 20px; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    font-size: 12px; }
  .ori-ant-design-container .ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background 0.3s ease; }
  .ori-ant-design-container .ant-select-dropdown-menu-item:hover {
    background-color: #e6f7ff; }
  .ori-ant-design-container .ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0; }
  .ori-ant-design-container .ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-selected,
  .ori-ant-design-container .ant-select-dropdown-menu-item-selected:hover {
    background-color: #fafafa;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-select-dropdown-menu-item-active {
    background-color: #e6f7ff; }
  .ori-ant-design-container .ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0; }
  .ori-ant-design-container .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e632";
    color: transparent;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    transition: all 0.2s ease;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    font-weight: bold;
    text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0; }
  .ori-ant-design-container :root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
    font-size: 12px; }
  .ori-ant-design-container .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover:after {
    color: #ddd; }
  .ori-ant-design-container .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled:after {
    display: none; }
  .ori-ant-design-container .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:after,
  .ori-ant-design-container .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover:after {
    color: #1890ff;
    display: inline-block; }
  .ori-ant-design-container .ant-select-dropdown-container-open .ant-select-dropdown,
  .ori-ant-design-container .ant-select-dropdown-open .ant-select-dropdown {
    display: block; }
  .ori-ant-design-container .ant-menu {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    line-height: 0;
    transition: background .3s, width .2s;
    zoom: 1; }
  .ori-ant-design-container .ant-menu:before,
  .ori-ant-design-container .ant-menu:after {
    content: " ";
    display: table; }
  .ori-ant-design-container .ant-menu:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0; }
  .ori-ant-design-container .ant-menu ul,
  .ori-ant-design-container .ant-menu ol {
    list-style: none;
    margin: 0;
    padding: 0; }
  .ori-ant-design-container .ant-menu-hidden {
    display: none; }
  .ori-ant-design-container .ant-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 16px;
    transition: all .3s; }
  .ori-ant-design-container .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-item:active,
  .ori-ant-design-container .ant-menu-submenu-title:active {
    background: #e6f7ff; }
  .ori-ant-design-container .ant-menu-submenu .ant-menu-sub {
    cursor: auto;
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-item > a {
    display: block;
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-menu-item > a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item > a:focus {
    text-decoration: none; }
  .ori-ant-design-container .ant-menu-item > a:before {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ''; }
  .ori-ant-design-container .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0; }
  .ori-ant-design-container .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-item-active,
  .ori-ant-design-container .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-submenu-title:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent; }
  .ori-ant-design-container .ant-menu-item-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item-selected > a,
  .ori-ant-design-container .ant-menu-item-selected > a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff; }
  .ori-ant-design-container .ant-menu-inline,
  .ori-ant-design-container .ant-menu-vertical,
  .ori-ant-design-container .ant-menu-vertical-left {
    border-right: 1px solid #e8e8e8; }
  .ori-ant-design-container .ant-menu-vertical-right {
    border-left: 1px solid #e8e8e8; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub {
    border-right: 0;
    padding: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    border-right: 0;
    margin-left: 0;
    left: 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .ori-ant-design-container .ant-menu-horizontal.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub {
    min-width: 160px; }
  .ori-ant-design-container .ant-menu-item,
  .ori-ant-design-container .ant-menu-submenu-title {
    cursor: pointer;
    margin: 0;
    padding: 0 20px;
    position: relative;
    display: block;
    white-space: nowrap;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu-submenu-title .anticon + span {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1; }
  .ori-ant-design-container .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    background-color: #e8e8e8; }
  .ori-ant-design-container .ant-menu-submenu-popup {
    position: absolute;
    border-radius: 4px;
    z-index: 1050; }
  .ori-ant-design-container .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 4px; }
  .ori-ant-design-container .ant-menu-submenu > .ant-menu-submenu-title:after {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px; }
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    content: '';
    position: absolute;
    vertical-align: baseline;
    background: #fff;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(45deg) translateY(-2px);
    transform: rotate(45deg) translateY(-2px); }
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(2px);
    transform: rotate(-45deg) translateY(2px); }
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
    background: linear-gradient(to right, #1890ff, #1890ff); }
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(-45deg) translateX(2px);
    transform: rotate(-45deg) translateX(2px); }
  .ori-ant-design-container .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(45deg) translateX(-2px);
    transform: rotate(45deg) translateX(-2px); }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(-45deg) translateX(-2px);
    transform: rotate(-45deg) translateX(-2px); }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(45deg) translateX(2px);
    transform: rotate(45deg) translateX(2px); }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-selected > a,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu-selected > a,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu-selected > a {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: none;
    line-height: 46px; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    float: left;
    border-bottom: 2px solid transparent; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-open,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-selected,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #1890ff;
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item > a,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu > a {
    display: block;
    color: rgba(0, 0, 0, 0.65); }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item > a:hover,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu > a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal:after {
    content: "\20";
    display: block;
    height: 0;
    clear: both; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item {
    position: relative; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    -webkit-transform: scaleY(0.0001);
    transform: scaleY(0.0001);
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 16px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu {
    padding-bottom: 0.01px; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px; }
  .ori-ant-design-container .ant-menu-vertical > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right > .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 40px;
    height: 40px; }
  .ori-ant-design-container .ant-menu-inline {
    width: 100%; }
  .ori-ant-design-container .ant-menu-inline .ant-menu-selected:after,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item-selected:after {
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  .ori-ant-design-container .ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px); }
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px; }
  .ori-ant-design-container .ant-menu-inline-collapsed {
    width: 80px; }
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    text-overflow: clip;
    padding: 0 32px !important; }
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none; }
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 16px;
    line-height: 40px;
    margin: 0; }
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    max-width: 0;
    display: inline-block;
    opacity: 0; }
  .ori-ant-design-container .ant-menu-inline-collapsed-tooltip {
    pointer-events: none; }
  .ori-ant-design-container .ant-menu-inline-collapsed-tooltip .anticon {
    display: none; }
  .ori-ant-design-container .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85); }
  .ori-ant-design-container .ant-menu-inline-collapsed .ant-menu-item-group-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 4px; }
  .ori-ant-design-container .ant-menu-item-group-list {
    margin: 0;
    padding: 0; }
  .ori-ant-design-container .ant-menu-item-group-list .ant-menu-item,
  .ori-ant-design-container .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px; }
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical,
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical-left,
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical-right,
  .ori-ant-design-container .ant-menu-root.ant-menu-inline {
    box-shadow: none; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline {
    padding: 0;
    border: 0;
    box-shadow: none;
    border-radius: 0; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 40px;
    height: 40px;
    list-style-type: disc;
    list-style-position: inside; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px; }
  .ori-ant-design-container .ant-menu-item-disabled,
  .ori-ant-design-container .ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
    background: none;
    border-color: transparent !important; }
  .ori-ant-design-container .ant-menu-item-disabled > a,
  .ori-ant-design-container .ant-menu-submenu-disabled > a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none; }
  .ori-ant-design-container .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-menu-dark,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: .45;
    transition: all .3s; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    background: #fff; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal {
    border-bottom-color: #001529; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    border-color: #001529;
    border-bottom: 0; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-group-title,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.65); }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    border-right: 0;
    margin-left: 0;
    left: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: transparent;
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > a {
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
    background: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected {
    border-right: 0;
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected:after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > a:hover {
    color: #fff; }
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ori-ant-design-container .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > a {
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.35) !important; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important; }
  .ori-ant-design-container .ant-message {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 100%;
    top: 16px;
    left: 0;
    pointer-events: none; }
  .ori-ant-design-container .ant-message-notice {
    padding: 8px;
    text-align: center; }
  .ori-ant-design-container .ant-message-notice:first-child {
    margin-top: -8px; }
  .ori-ant-design-container .ant-message-notice-content {
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: #fff;
    display: inline-block;
    pointer-events: all; }
  .ori-ant-design-container .ant-message-success .anticon {
    color: #52c41a; }
  .ori-ant-design-container .ant-message-error .anticon {
    color: #f5222d; }
  .ori-ant-design-container .ant-message-warning .anticon {
    color: #faad14; }
  .ori-ant-design-container .ant-message-info .anticon,
  .ori-ant-design-container .ant-message-loading .anticon {
    color: #1890ff; }
  .ori-ant-design-container .ant-message .anticon {
    margin-right: 8px;
    font-size: 16px;
    top: 1px;
    position: relative; }
  .ori-ant-design-container .ant-message-notice.move-up-leave.move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
    animation-name: MessageMoveOut;
    overflow: hidden;
    -webkit-animation-duration: .3s;
    animation-duration: .3s; }

@-webkit-keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  100% {
    opacity: 0;
    max-height: 0;
    padding: 0; } }

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  100% {
    opacity: 0;
    max-height: 0;
    padding: 0; } }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.ant-message {
  position: absolute !important;
  z-index: 999999 !important; }

.ori-tag-primary {
  color: #ed3837 !important;
  background-color: #fdebeb !important;
  border: 1px solid transparent !important;
  cursor: default !important;
  height: auto !important;
  line-height: unset !important; }

.ori-btn-default:hover, .ori-btn-default:focus {
  color: #ed3837 !important;
  border: 1px solid #ed3837 !important; }

.ori-btn-ghost-primary {
  color: #ed3837 !important;
  border: 1px solid #ed3837 !important;
  height: auto !important;
  line-height: unset !important; }
  .ori-btn-ghost-primary:hover, .ori-btn-ghost-primary:focus, .ori-btn-ghost-primary.active {
    box-shadow: 0 0 6px 0 #ed3837; }

.ori-btn-fill-primary, .ori-btn-bubble-inner {
  color: #ffffff !important;
  background-color: #ed3837 !important;
  border-color: #ed3837 !important; }
  .ori-btn-fill-primary:hover, .ori-btn-fill-primary:focus, .ori-btn-fill-primary.active, .ori-btn-bubble-inner:hover, .ori-btn-bubble-inner:focus, .ori-btn-bubble-inner.active {
    background-color: #f27473 !important;
    border-color: #f27473 !important; }
  .ori-btn-fill-primary:hover, .ori-btn-bubble-inner:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3); }

.ori-btn-tag-primary {
  color: #ed3837 !important;
  background-color: #fdebeb !important;
  border: 1px solid transparent !important;
  height: auto !important;
  line-height: unset !important; }
  .ori-btn-tag-primary:hover, .ori-btn-tag-primary:focus {
    border-color: #ed3837 !important;
    background-color: #ed3837 !important;
    color: #ffffff !important; }

.ori-mt-CarouselContainer .slick-arrow {
  background: none !important;
  font-size: 10px !important;
  color: #abaeb2 !important;
  width: auto !important;
  top: inherit !important;
  bottom: -28px !important;
  z-index: 1 !important; }

.ori-mt-CarouselContainer .slick-next {
  right: 10px !important; }
  .ori-mt-CarouselContainer .slick-next:hover, .ori-mt-CarouselContainer .slick-next:focus {
    color: #3c4043 !important; }
  .ori-mt-CarouselContainer .slick-next::before {
    content: "" !important;
    display: unset !important; }

.ori-mt-CarouselContainer .slick-prev {
  left: 10px !important; }
  .ori-mt-CarouselContainer .slick-prev:hover, .ori-mt-CarouselContainer .slick-prev:focus {
    color: #3c4043 !important; }
  .ori-mt-CarouselContainer .slick-prev::before {
    content: "" !important;
    display: unset !important; }

.ori-mt-CarouselContainer .slick-dots {
  bottom: -10px !important;
  overflow-x: hidden; }
  .ori-mt-CarouselContainer .slick-dots li, .ori-mt-CarouselContainer .slick-dots .slick-active {
    width: unset !important; }
    .ori-mt-CarouselContainer .slick-dots li button, .ori-mt-CarouselContainer .slick-dots .slick-active button {
      background-color: #3c4043 !important; }
      .ori-mt-CarouselContainer .slick-dots li button::before, .ori-mt-CarouselContainer .slick-dots .slick-active button::before {
        font-size: unset !important; }

.ori-mt-CarouselContainer .slick-slide {
  margin: 0 !important; }

.ori-mt-fileUploaderContainer .ant-upload {
  width: 100%; }

.ori-app-container { }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.oriAppContainer {
  z-index: 99990;
  position: fixed;
  right: 0;
  bottom: 0; }
  .oriAppContainer .notificationBadge {
    top: -18px;
    right: 24px; }
    .oriAppContainer .notificationBadge .ant-badge-count {
      z-index: 99993;
      background-color: #35cd4a;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11); }

