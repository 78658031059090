@import "./colors.scss";
@import "./variables.scss";

.ant-message {
    position: absolute !important;
    z-index: 999999 !important;
}

//------------------------ TAG CLASSES ----------------------------

.ori-tag-primary {
    color: $primary !important;
    background-color: $primary-bg-light !important;
    border: 1px solid transparent !important;
    cursor: default !important;
    height: auto !important;
    line-height: unset !important;
}

//------------------------ BUTTON CLASSES -------------------------

.ori-btn-default {
    &:hover, &:focus {
        color: $primary !important;
        border: 1px solid $primary !important;
    }
}

.ori-btn-ghost-primary {
    color: $primary !important;
    border: 1px solid $primary !important;
    height: auto !important;
    line-height: unset !important;

    &:hover, &:focus, &.active {
        box-shadow: 0 0 6px 0 $primary;
    }
}

.ori-btn-fill-primary, .ori-btn-bubble-inner{
    color: $white !important;
    background-color: $primary !important;
    border-color: $primary !important;

    &:hover, &:focus, &.active {
        background-color: $primary-light !important;
        border-color: $primary-light !important;
    }

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
}

.ori-btn-tag-primary {
    color: $primary !important;
    background-color: $primary-bg-light !important;
    border: 1px solid transparent !important;
    height: auto !important;
    line-height: unset !important;

    &:hover, &:focus {
        border-color: $primary !important;
        background-color: $primary !important;
        color: $white !important;
    }
}

//------------------------------- MESSAGE-TYPES -----------------------------

.ori-mt-CarouselContainer {
    .slick-arrow {
        background: none !important;
        font-size: 10px !important;
        color: $font-color-light !important;
        width: auto !important;
        top: inherit !important;
        bottom: -28px !important;
        z-index: 1 !important;
    }

    .slick-next {
        right: 10px !important;

        &:hover, &:focus {
            color: $font-color !important;
        }

        &::before {
            content: "" !important;
            display: unset !important; // idea specific css changes
       
        }
    }

    .slick-prev {
        left: 10px !important;

        &:hover, &:focus {
            color: $font-color !important;
        }

        &::before {
            content: "" !important;
            display: unset !important; // idea specific css changes
       
        }
    }

    .slick-dots {
        bottom: -10px !important;
        overflow-x: hidden;

        li, .slick-active {
            width: unset !important; // idea specific css changes

            button {
                background-color: $font-color !important;

                &::before {
                    font-size: unset !important; // idea specific css changes
               
                }
            }
        }
    }

    //------------- idea specific css changes ----------------
    .slick-slide {
        margin: 0 !important;
    }
}

.ori-mt-fileUploaderContainer {
    .ant-upload {
        width: 100%;
    }
}
